import React from 'react';
import './Stage.css';
function radius(count, i) {
    if (count == 19) {
        if (i == 1)
            return 0;
        if (i < 8)
            return 1;
        return 2;
    }
    else if (count == 9) {
        if (i == 9)
            return 0;
        return 1;
    }
    else if (count == 7) {
        if (i == 7)
            return 0;
        return 1;
    }
    return -1;
}
function period(count, i) {
    if (count == 19) {
        if (i == 1)
            return 1;
        if (i < 8)
            return 6;
        return 12;
    }
    else if (count == 7) {
        if (i == 7)
            return 1;
        return 6;
    }
    else if (count == 9) {
        if (i == 9)
            return 1;
        return 8;
    }
    return -1;
}
export default function StageForm(props) {
    const stroke = 4;
    const c = props.size / 2;
    let size = props.size / 12;
    if (props.count !== 19) {
        size = props.size / 9;
    }
    const plates = Array.from(Array(props.count).keys());
    let radii = [];
    let shift = [];
    if (props.count == 19) {
        radii = [0, 2 * size + 4, 4 * size + 6];
        shift = [0, 0, Math.PI];
    }
    else {
        radii = [0, 2.1 * size + 10];
        shift = [0, Math.PI];
    }
    return (<svg height={props.size} width={props.size}>
        <circle cx={c} cy={c} r={props.size / 2 - 2 * stroke} stroke="silver" strokeWidth={stroke} fill="silver"/>
        {plates.map((i) => {
            const r = radii[radius(props.count, i + 1)];
            const T = period(props.count, i + 1);
            const rx = Math.sin(-2 * Math.PI * i / T + shift[radius(props.count, i + 1)]) * r;
            const ry = Math.cos(-2 * Math.PI * i / T + shift[radius(props.count, i + 1)]) * r;
            const isAvailable = props.available.includes(i + 1);
            const classModifier = isAvailable ? (props.selected === (i + 1) ? '-sel' : '') : '-dis';
            const color = isAvailable ? 'black' : 'gray';
            return (<g key={`g${i}`} onClick={() => {
                    if (isAvailable) {
                        props.onKeyClick(i + 1);
                    }
                }}>
              <circle cx={rx + c} cy={ry + c} r={size} className={`plate${classModifier}`}/>
              <text fill={color} x={rx + c} y={ry + c} dominantBaseline="middle" textAnchor="middle" pointerEvents={'none'}>{i + 1}</text>
            </g>);
        })}
      </svg>);
}
