import React, { useState } from 'react'
import './Overlays.css'
import { Overlay } from 'react-bootstrap'

export function Tooltip({show, children}) {
  //if(children.length !== 2) 
  return (
    <div 
      className='tooltip-container' 
      style={(show !== undefined && show === false) ? {display: 'none'} : {}}
    >
      <div className='tooltip-root'>{children[0]}</div>
      <div className='tooltip-content'>{children[1]}</div>
    </div>
  )
}

export function ClickableTooltip({show, children}) {
  //if(children.length !== 2) 
  const [visible, setVisible] = useState(false)
  return (
    <div 
      className='tooltip-container' 
      style={(show !== undefined && show === false) ? {display: 'none'} : {}}
    >
      <div className='tooltip-root' onClick={() => setVisible(v => !v)}>{children[0]}</div>
      <div className='tooltip-content' style={visible ? {visibility: 'visible'} : {}}>{children[1]}</div>
    </div>
  )
}

/*
  div that can be hidden
*/ 
export function OptionalView({show, children}) {
  return (
    <div style={show ? {} : { display: 'none' }}>
      {show ? children : <span />}
    </div>
  )
}

export function BlurOverlay({poiRef, show, placement, className, children}) {
  return (
    <Overlay show={show} target={poiRef} placement={placement}>
      {({ placement, arrowProps, show: _show, popper, ...props_ }) => (
        <div {...props_} className='progress-viewer-error-container' style={{
            ...props_.style,
        }}>
          {children}
        </div>
      )}
    </Overlay>
  )
}