import React, { useEffect, useState, useRef } from 'react';
import './StatusBar.css';
import { ConnectionMenu } from './tabs/settings/Connection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faCog } from '@fortawesome/free-solid-svg-icons';
import { Overlay, Button } from 'react-bootstrap';
import { ServerVersion } from './tabs/Settings';
import { Cloud, CloudOff } from '@material-ui/icons';
/**
 *
 * @param props.offline The ping timed out or was refused
 *
 */
function ServerView(props) {
    let color = 'white';
    if (!props.offline)
        color = '#3DED97'; //#99EDC3: mint, #03c04A: parakeet, #607D3B: crocodilwe, #3DED97: seafoam
    else
        color = 'indianred';
    const [showLogs, setShowLogs] = useState(false);
    const [online, setOnline] = useState(false);
    const [tab, setTab] = useState();
    const target = useRef(null);
    useEffect(() => {
        //server was online and is now offline - long transition
        if (props.offline && online) {
            setTab('notif-off');
            const transition = setTimeout(() => {
                setOnline(!props.offline);
                setTab(t => t === 'notif-off' ? undefined : t);
            }, 5000);
            return () => {
                clearTimeout(transition);
            };
        }
        //server was offline and is now online
        if (!props.offline && !online) {
            setTab('notif-on');
            const transition = setTimeout(() => {
                setOnline(!props.offline);
                setTab(t => t === 'notif-on' ? undefined : t);
            }, 5000);
            return () => clearTimeout(transition);
        }
    }, [props.offline]);
    return (<div>
      <div className={tab === undefined ? "server-status-btn" : "server-status-btn-open"} ref={target} onClick={() => setTab(t => t === 'menu' ? undefined : 'menu')}>
        {!props.offline ? <Cloud /> : <CloudOff />}
        <FontAwesomeIcon icon={tab === 'menu' ? faChevronDown : faChevronUp} style={{ marginLeft: '8px' }}/>
      </div>
      <Overlay target={target.current} show={tab !== undefined} placement="top">
          {({ placement, arrowProps, show: _show, popper, ...props_ }) => (<div {...props_} style={{
                backgroundColor: 'rgba(0, 0, 0, 0.85)',
                padding: '2px 10px',
                color: 'white',
                borderRadius: 3,
                width: '280px',
                ...props_.style,
            }}>
              <div>
                <span style={tab === 'notif-on' ? {} : { display: 'none' }}>Server back online</span>
                <div style={tab && ['menu', 'notif-off'].includes(tab) ? {} : { display: 'none' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h5>{props.offline ? 'Server offline' : <span>Server api-v<ServerVersion address={props.url} online={!props.offline} active={true}/> </span>}</h5>
                    <Button size="sm" variant="dark" onClick={props.fixConnection}>
                      Settings
                      <FontAwesomeIcon icon={faCog}/>
                    </Button>
                  </div>
                </div>
              </div>
            </div>)}
      </Overlay>
    </div>);
}
export const PROXY_PING_PERIOD = 10000;

/**
    http: client with get and post methods. returns the usual responses
    */
export default function StatusBar({api}) {
  const [state, setState] = useState({apiVersion: "", logsTail: ""})
    useEffect(() => {
      let logsHook
        if(api && api.url) {
          console.log("get version")
          api.text("/version", (v) => setState(p => Object.assign({}, p, {apiVersion: v})))
          logsHook = api.stream("/logs", {
            onMessage: log => setState(prev => {
              return Object.assign({}, prev, {logsTail: log})
            })
          })
        }
        return () => {
          logsHook && logsHook.close()
        }
    }, [api && api.url])
    return (
      <div className="status-bar">
        <div className='status-bar-api'>
          <FontAwesomeIcon className='status-bar-api-icon' icon="globe" />
          API
          <span className='status-bar-spaced-item'>{api && api.url && api.url.replace("https://", "")}</span>
          @<span>{state.apiVersion.replace(/"/g, "")}</span>
        </div>
        <span>{state.logsTail && state.logsTail["msg"].substring(0,200)}</span>
      </div>
    )
}

/*
<small>
          <div style={{ display: 'flex', height: '24px', lineHeight: '24px', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>
              {false !api.isLive() && (
                <span >
                  <CloudOff fontSize='small'/>
                  Offline
                </span>
              )}
              <span>
                API {api.url} 
              </span>
            </div>
            {false && <ServerView 
                api={api} 
                offline={!live.value} 
                recentlyConnected={recentOnline} 
                fixConnection={fixConnection}/>}
            {false && <ConnectionMenu 
                conns={connections} 
                isLoading={false} 
                api={api} />}
          </div>
        </small>
*/