import React, { useEffect, useState, useRef } from 'react';
import './Connection.css';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { TextField, Checkbox, Switch } from '@material-ui/core';
import { Button, Dropdown, DropdownButton, Overlay, OverlayTrigger, Tooltip, Badge, Spinner, ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faInfoCircle, faMicroscope, faChevronRight, faStopwatch, faRedoAlt, faTimes, faChevronUp, faChevronDown, faCross, faSave } from '@fortawesome/free-solid-svg-icons';
import ClearIcon from '@material-ui/icons/Clear';
import { v4 as uuidv4 } from 'uuid';
//background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
const useStyles = makeStyles({
    root: {
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        padding: '0 30px',
    },
});
const theme = createMuiTheme({
    palette: {
        type: "dark"
    }
});
export default function Hook() {
    const classes = useStyles();
    return (<ThemeProvider theme={theme}>
      <TextField id="standard-basic" label="Standard"/>
    </ThemeProvider>);
}
const FAKE_OPS = ['<', '>', '>=', '<=', 'startsWith', '='];
export const TEST_MANUFACTURER = {
    "manufacturers": [
        {
            "name": "Zeiss",
            "logo": "Zeiss_logo.svg",
            "plates": {
                "large": {
                    "centers": [
                        { "idx": 1, "x": 0.06223, "y": 0.06029 },
                        { "idx": 2, "x": 0.07147, "y": 0.07323 },
                        { "idx": 3, "x": 0.07805, "y": 0.05897 },
                        { "idx": 4, "x": 0.06828, "y": 0.04618 },
                        { "idx": 5, "x": 0.05257, "y": 0.04776 },
                        { "idx": 6, "x": 0.04617, "y": 0.06274 },
                        { "idx": 7, "x": 0.05541, "y": 0.07531 },
                        { "idx": 8, "x": 0.0575, "y": 0.09145 },
                        { "idx": 9, "x": 0.07298, "y": 0.08918 },
                        { "idx": 10, "x": 0.08594, "y": 0.07966 },
                        { "idx": 11, "x": 0.09252, "y": 0.06503 },
                        { "idx": 12, "x": 0.09050, "y": 0.04915 },
                        { "idx": 13, "x": 0.08115, "y": 0.03652 },
                        { "idx": 14, "x": 0.06638, "y": 0.02977 },
                        { "idx": 15, "x": 0.05026, "y": 0.03192 },
                        { "idx": 16, "x": 0.03741, "y": 0.04170 },
                        { "idx": 17, "x": 0.031, "y": 0.05616 },
                        { "idx": 18, "x": 0.03282, "y": 0.07230 },
                        { "idx": 19, "x": 0.04243, "y": 0.08486 }
                    ], radius: 0.003
                }
            },
            settings: [
                { name: "brightness", dtype: "float", readOnly: false },
                { name: "contrast", dtype: "float", readOnly: false },
                { name: "scan rate", dtype: "int", readOnly: false },
                { name: "noise reduction", dtype: "int", readOnly: false },
                { name: "path", dtype: "float", readOnly: true }
            ]
        }
    ]
};
function PreconditionsForm(props) {
    const [settingName, setSettingName] = useState();
    const settingClick = (setting) => () => {
        const cpy = Object.assign({}, props.precondition);
        cpy.setting = setting;
        props.setPrecondition(cpy);
    };
    const opClick = (op) => () => {
        const cpy = Object.assign({}, props.precondition);
        cpy.operator = op;
        props.setPrecondition(cpy);
    };
    const constantChange = (e) => {
        const cpy = Object.assign({}, props.precondition);
        cpy.constant = e.target.value;
        props.setPrecondition(cpy);
    };
    const blockingChange = () => {
        const cpy = Object.assign({}, props.precondition);
        cpy.blocking = !cpy.blocking;
        props.setPrecondition(cpy);
    };
    return (<div className="precondition-row">
      <div style={{ marginRight: '16px', width: '400px', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
        <DropdownButton title={props.precondition.setting.name} size="sm" variant="light">
          {props.availableSettings.map((setting) => (<Dropdown.Item key={setting.name + "dropdown-item"} onClick={settingClick(setting)}>{setting.name}</Dropdown.Item>))}
        </DropdownButton>
        <DropdownButton title={props.precondition.operator} size="sm" variant="light" style={{ marginLeft: '16px' }}>
          {FAKE_OPS.map((op) => (<Dropdown.Item key={"op-" + op} onClick={opClick(op)}>{op}</Dropdown.Item>))}
        </DropdownButton>
      </div>
      <ThemeProvider theme={theme}>
        <TextField size="small" fullWidth label="Constant" variant="outlined" value={props.precondition.constant} onChange={constantChange}/>
      </ThemeProvider>
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: '16px' }}>
        <label>Blocking</label>
        <Checkbox checked={props.precondition.blocking} onChange={blockingChange}/>
      </div>
      <ClearIcon onClick={props.clear}/>
    </div>);
}
/*export async function fetchConnections(): Promise<Array<Connection>> {

}*/
export function ManufacturerSelect(props) {
    //const [manufacturers, setManufacturers] = useState<Array<[Manufacturer, Manufacturer?]>>([])
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const select = (manufacturer) => () => {
        props.selectManufacturer(manufacturer);
    };
    return (<div className="manufacturer-select-container">
      <div ref={target} style={{ display: 'flex' }}>
        <img src={`data:image/svg+xml;base64, ${props.selected.logo}`} alt="Logo" height='48px' className='manufacturer-logo'/>
        <FontAwesomeIcon icon={faChevronDown} onClick={() => setShow(true)}/>
      </div>
      <Overlay target={target.current} show={show} placement="right">
        {({ placement, arrowProps, show: _show, popper, ...props2 }) => (<div {...props2} style={{
                backgroundColor: 'black',
                padding: '2px 10px',
                color: 'white',
                borderRadius: 3,
                ...props2.style,
            }}>
            <FontAwesomeIcon icon={faCross} onClick={() => setShow(false)}/>
            {props.manufacturers.map((m) => {
                const classMod = m.name === props.selected.name ? '-select' : '';
                return (<div onClick={select(m)} className={`manufacturer-logo-overlay${classMod}`}>
                  <img src={`data:image/svg+xml;base64, ${m.logo}`} alt="Logo" width='80px' className='manufacturer-logo'/>
                </div>);
            })}
          </div>)}
      </Overlay>
    </div>);
}
function ConnectionParameters(props) {
    const [parameters, setParameters] = useState();
    const add = () => {
        let tmp = {};
        if (parameters) {
            tmp = Object.assign(tmp, parameters);
        }
        tmp[""] = "";
        setParameters(tmp);
    };
    return (<div>
      
      <div className="single-end-aligned">
        <Button variant="light" onClick={add}>+ Add</Button>
      </div>
    </div>);
}
function PluginsMarketplace({show, hide, api, addPlugin}) {
    const [availablePlugins, setAvailablePlugins] = useState([])
    useEffect(() => {
      if(api.isLive()) {
        api.get("/plugins", {
          json: (plugins) => {
            setAvailablePlugins(plugins ? plugins : [] )
          }
        })
      }
    }, [api])
    if (!show)
        return <span></span>
    return (<div className="full-page-overlay-container">
      <div className='plugin-marketplace-container'>
        <h5>Plugin Marketplace</h5>
        <button onClick={hide}>Close</button>
        <div className="plugin-marketplace-cards">
          {availablePlugins.map((p) => {
            return (
                <div className="plugin-marketplace-card">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>{p.pluginId}</span>
                    <Badge>{p.pluginType}</Badge>
                    </div>
                    <p>{p.description}</p>
                    <Button size="sm" onClick={() => addPlugin(p)}>Add</Button>
                </div>
            )
        })}
        </div>
      </div>
    </div>);
}
function PluginView(props) {
    return (<div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'black', borderRadius: '8px', padding: '12px', margin: '8px' }}>
      {props.plugin.pluginId}
      <p style={{ fontWeight: 'lighter' }}>{props.plugin.description}</p>
      {props.plugin.parameters.map((param) => {
            if (param.scopes && !param.scopes.includes('workspace'))
                return '';
            return (<div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', width: '240px', justifyContent: 'space-between', marginRight: '16px' }}>
              <span style={{}}>{param.name ? param.name : param.paramId}</span>
              <OverlayTrigger trigger="hover" overlay={<Tooltip id={`${param.paramId}-desc`}>{param.description}</Tooltip>}>
                <FontAwesomeIcon icon={faInfoCircle}/>
              </OverlayTrigger>
            </div>
            <TextField value={param.value} fullWidth/>
            
          </div>);
        })}
    </div>);
}
function WorkspacePluginSettings({plugins, setPlugins, api}) {
    const [editId, setEditId] = useState(-1);
    const [showMarketplace, setShowMarketplace] = useState(false)
    const addPlugin = (p) => {
        const plugins_ = plugins.slice().concat([p])
        setPlugins(plugins_)
        setShowMarketplace(false)
    }
    return (<div style={{}}>
      <h5>Plugins</h5>
      <div className="plugin-chips">
        <div className="plugin-chip" onClick={() => setShowMarketplace(true)}>
          Add Plugin 
        </div>
        {plugins.map((plugin, i) => (<div className="plugin-chip" onClick={() => setEditId(i)}>
            {plugin.pluginId}
          </div>))}
      </div>
      <PluginsMarketplace 
        show={showMarketplace} 
        api={api} 
        addPlugin={addPlugin} 
        hide={() => setShowMarketplace(false)}/>
      {plugins[editId] && <PluginView plugin={plugins[editId]}/>}
    </div>);
}
function keyValueToDict(kvs) {
    return Object.fromEntries(kvs.map(({ key, value }) => [key, value]));
}
function WorkspaceParameters(props) {
    const initParams = Object.entries(props.parameters).map(([k, v]) => ({ key: k, value: v }));
    const [params, setParams] = useState(initParams);
    const addRow = () => {
        setParams(params.slice().concat([{ key: "", value: "" }]));
    };
    const update = (key, idx) => (e) => {
        const cpy = params.slice();
        cpy[idx][key] = e.target.value;
        setParams(cpy);
        props.setParameters(keyValueToDict(cpy));
    };
    return (<div>
      <h4>Parameters</h4>
      {params.map(({ key, value }, i) => (<div style={{ display: 'flex' }}>
          <TextField value={key} onChange={update('key', i)}/>
          <TextField value={value} onChange={update('value', i)}/>
        </div>))}
      <Button onClick={addRow}>Add</Button>
    </div>);
}
//function WorkspaceCard()
export function WorkspaceSettings({api, workspace, update, active, manufacturers}) {
    const unset = workspace.status === 'unset';
    const [manufacturer, setManufacturer] = useState(workspace.microscope.manufacturer) //FAKE_DATA.manufacturers[0]//
    const [general, setGeneral] = useState({ 
        ip: workspace.microscope.ip, 
        port: workspace.microscope.port.toString(), 
        name: workspace.name 
    })
    const [plugins, setPlugins] = useState(workspace.plugins ? workspace.plugins : [])
    const [saved, setSaved] = useState(!unset); // on connection creation, the user must click save even if no parameter was changed(simplifies POST/PUT)
    const [reconnecting, setReconnecting] = useState(false);
    const [parameters, setParameters] = useState(workspace.env ? workspace.env : {})
    const [isDisabled, setIsDisabled] = useState(workspace.status === 'disabled')
    const generalChange = (key) => (e) => {
        setGeneral(Object.assign({}, general, { [key]: e.target.value }));
        setSaved(false);
    };
    const selectManufacturer = (m) => {
        setManufacturer(m);
        setSaved(false);
    };
    const isValidPortNumber = (port) => !isNaN(+port) && +port < 65535;
    const save = () => {
        //TODO fetch.then(TODO update ...)
        let workspaceId = workspace.workspaceId;
        if (!workspaceId) {
            workspaceId = uuidv4();
        }
        let data = {
            microscope: { ip: general.ip, port: +general.port, manufacturer: manufacturer },
            name: general.name, workspaceId: workspaceId, status: isDisabled ? 'disabled' : 'ready',
            env: parameters,
            plugins: plugins
        };
        const method = unset ? 'POST' : 'PUT';
        const id = unset ? '' : `/${data.workspaceId}`
        const body = JSON.stringify(data)
        api.call(`/workspaces${id}`, method, {
            json: (res) => {
                if (unset) {
                    data.workspaceId = res.connectionId;
                }
                update(data);
                setSaved(true);
            },
            error: (err) => {
                console.log(err);
            }
        }, {body: body})
    };
    const clear = () => {
        api.delete(`/workspaces/${workspace.workspaceId}`, {
            json: () => update(null)
        })
    }
    const reconnect = () => {
        /*setReconnecting(true);
        fetch(`${url}/connections/${workspace.workspaceId}/reconnect`).then((res) => {
            setReconnecting(false);
        })*/
    }
    const canSave = !saved && isValidPortNumber(general.port);
    useEffect(() => {
        setManufacturer(workspace.microscope.manufacturer);
        setGeneral({
            ip: workspace.microscope.ip,
            port: workspace.microscope.port.toString(),
            name: workspace.name
        });
        setPlugins(workspace.plugins ? workspace.plugins : []);
        setSaved(true);
    }, [JSON.stringify(workspace)]);
    if (!active)
        return <span />;
    return (<ThemeProvider theme={theme}>
      <div>
        <div className="connection-form-title">
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <ManufacturerSelect 
                selected={manufacturer} 
                manufacturers={manufacturers} 
                selectManufacturer={selectManufacturer}/>
          </div>
          <input 
            className="workspace-name-form" 
            style={{ background: 'none', textAlign: 'center' }} 
            value={general.name} 
            onChange={generalChange('name')}/>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon 
                className="workspace-settings-icon" 
                icon={faSave} 
                style={canSave ? {} : { display: 'none' }} 
                onClick={save}/>
            <Switch 
                checked={!isDisabled} 
                onChange={() => {
                    setIsDisabled(i => !i);
                    setSaved(false);
                }}/>
            <FontAwesomeIcon 
                className="workspace-settings-icon" 
                icon={faRedoAlt} 
                onClick={reconnect} 
                style={reconnecting ? { display: 'none' } : {}}/>
            <Spinner 
                animation="border" 
                size="sm" 
                style={reconnecting ? {} : { display: 'none' }}/>
            <FontAwesomeIcon 
                className="workspace-settings-icon" 
                icon={faTimes} 
                onClick={clear}/>
          </div>
        </div>
        <small>{workspace.workspaceId}</small>
        <h5>Address</h5>
        <div className="connection-form-row">
          <TextField 
            fullWidth 
            style={{ marginRight: '8px' }} 
            label="IPv4/Domain" 
            value={general.ip} 
            onChange={generalChange('ip')} 
            id="conn-settings-ip-input"/>
          <TextField 
            fullWidth 
            label="Port" 
            value={general.port} 
            onChange={generalChange('port')} 
            id="conn-settings-port-input" 
            error={!isValidPortNumber(general.port)}/>
        </div>
        <WorkspacePluginSettings 
            api={api}
            show={true} 
            plugins={plugins} 
            setPlugins={(ps) => {
                setSaved(false)
                setPlugins(ps)
            }}
        />
      </div>
    </ThemeProvider>
    )
}
// TODO compute available settings
// TODO implement new connection fetch...
//TODO implement manufacturers 
/*


        <WorkspaceParameters parameters={parameters} setParameters={(params: Record<string, string>) => {
          setParameters(params)
          setSaved(false)
        }} />

*/
function intersection() {
}
function computeUpdate(prev, curr) {
    const diff = [];
    let newPrev;
    if (prev) {
        newPrev = Object.assign({}, prev);
        const backup = Object.assign({}, prev);
        for (let conn of curr) {
            if (conn.workspaceId in prev) {
                delete backup[conn.workspaceId];
                if (conn.status !== prev[conn.workspaceId].status) {
                    newPrev[conn.workspaceId] = conn;
                    diff.push(['update', conn.name, conn.status]); // and error?
                }
            }
            else {
                newPrev[conn.workspaceId] = conn;
                diff.push(['in', conn.name, conn.status]);
            }
        }
        Object.entries(backup).forEach(([connId, hb]) => {
            delete newPrev[connId];
            diff.push(['out', hb.name, hb.status]);
        });
        //setPreviousConns(cpy)
    }
    else {
        newPrev = {};
        for (let conn of curr) {
            diff.push(['in', conn.name, conn.status]);
            newPrev[conn.workspaceId] = conn;
        }
    }
    diff.sort();
    return [newPrev, diff];
}

const STATUS_VARIANT = {
    failing: 'danger',
    idle: 'primary',
    ready: 'primary',
    disabled: 'light',
    busy: 'success',
    deleted: 'danger',
    unset: 'danger'
};
function RequestProgess(props) {
    if (!props.req)
        return <span />;
    const waiting = props.req.status === 'waiting' || !props.req.numberOfSamples;
    const label = waiting ? 'waiting' : `${props.req.progress + 1}/${props.req.numberOfSamples}`;
    const now = waiting || !props.req.numberOfSamples ? 100 : (100 * (props.req.progress + 1)) / props.req.numberOfSamples;
    return (<div className="conn-menu-request-progress-container" style={props.show ? {} : { display: 'none' }}>
      <strong>{props.req.title}</strong> 
      <ProgressBar style={{ marginLeft: '8px', marginRight: '8px', flexGrow: 1 }} striped={waiting} animated={waiting} label={label} now={now}/> 
      {props.req?.elapsed_time ? <small style={{ marginRight: '8px' }}><FontAwesomeIcon icon={faStopwatch}/> {props.req.elapsed_time.toPrecision(3)}s </small> : <span />}
    </div>);
}
function ConnectionDashboard(props) {
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState();
    useEffect(() => {
        if (props.conn.status === 'busy') {
            const poll = setInterval(() => {
                fetch(`${props.url}/connections/${props.conn.workspaceId}/requests`).then(res => res.json()).then(setRequest);
            }, 500);
            return () => clearInterval(poll);
        }
    }, [props.conn.status]);
    const reconnect = () => {
        setLoading(true);
        fetch(`${props.url}/connections/${props.conn.workspaceId}/reconnect`).then(res => res.json).then(res => {
            setLoading(false);
        });
    };
    return (<div>
      <div className="conn-menu-title-container">
        <div>
          <strong style={{ marginRight: '8px' }}>{props.conn.name}</strong> 
          <Badge variant={STATUS_VARIANT[props.conn.status]}>{props.conn.status}</Badge>
        </div>
        <div>
          <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip id={"tooltip-" + props.conn.workspaceId}>Reconnnect</Tooltip>}>
            {!loading ? <FontAwesomeIcon className="conn-menu-reconnect-btn" icon={faRedoAlt} onClick={reconnect}/> : <Spinner animation="border" size="sm"/>}
          </OverlayTrigger>
          <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip id={"tooltip-2-" + props.conn.workspaceId}>Disable</Tooltip>}>
            <Switch checked={props.conn.status !== 'disabled'}/>
          </OverlayTrigger>
        </div>
      </div>
      <div className="conn-menu-details-container">
        <div className="conn-menu-error-title" style={props.conn.status === 'failing' ? {} : { display: 'none' }}>Details</div>
        <p style={props.conn.status === 'failing' ? {} : { display: 'none' }}>
          {props.conn.error?.digest}
        </p>
        <RequestProgess show={props.conn.status === 'busy'} req={request}/>
      </div>
    </div>);
}
function timeDeltaRepr(now, start) {
    const seconds = Math.floor((now - start) / 1000);
    if (seconds < 5)
        return 'now';
    if (seconds < 60)
        return `${seconds} seconds ago.`;
    if (seconds < 3600)
        return `${Math.floor(seconds / 60)} minutes ago`;
    return `${Math.floor(seconds / 3600)} hours ago`;
}
function ConnectionsActivity(props) {
    const [activity, setActivity] = useState([]);
    const check = props.diff.toString();
    useEffect(() => {
        if (props.diff.length > 0) {
            const equal = activity.length == 0 ? false : (activity[0][0] === props.diff[0][0] && activity[0][1] === props.diff[0][1] && activity[0][2] === props.diff[0][2]);
            if (!equal) {
                console.log(props.diff);
                const activity_ = activity.slice();
                for (let [kind, name, status] of props.diff) {
                    activity_.unshift([kind, name, status, new Date()]);
                }
                setActivity(activity_.slice(0, 20));
            }
        }
    }, [check]);
    const now = new Date();
    if (!props.show && props.show !== undefined)
        return <span />;
    return (<div className="">
      {activity.map(([kind, name, status, date]) => {
            return (<div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '16px' }}>
            <span><strong>{name}</strong> is now {status}</span>
            <small style={{ color: 'gray' }}>{timeDeltaRepr(now, date)}</small>
          </div>);
        })}
    </div>);
}
function MenuView(props) {
    const [tab, setTab] = useState('connections');
    if (!props.show)
        return <span />;
    return (<div>
      <div className="conn-menu-tabs">
        <h4 onClick={() => setTab('connections')} className={`conn-menu-tab-${tab === 'connections' ? 'active' : 'inactive'}`}>Connections</h4>
        <h4 onClick={() => setTab('activity')} className={`conn-menu-tab-${tab === 'activity' ? 'active' : 'inactive'}`}>Activity</h4>
      </div>
      <div className="conn-menu-menu-container">
      {props.conns && tab === 'connections' ? props.conns.filter((conn) => conn.status !== 'deleted').map((conn) => {
            return <ConnectionDashboard conn={conn} url={props.url} key={"dashboard" + conn.workspaceId}/>;
        }) : <span />}
      <ConnectionsActivity diff={props.diff} show={tab === 'activity'}/>
      </div>
    </div>);
}
function UpdatesView(props) {
    if (props.diff.length == 0 || !props.show)
        return <span />;
    return (<div id='tooltip-status-change-container'>
      {props.diff.map(([kind, name, status]) => {
            if (kind === 'in') {
                return <div id="tooltip-text-in">{name} is connected and {status}</div>;
            }
            if (kind === 'out') {
                return <div>{name} disconnected</div>;
            }
            return <div>{name} is now {status}</div>;
        })}
    </div>);
}
export function ConnectionMenu(props) {
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [previousConns, setPreviousConns] = useState();
    const [assets, setAssets] = useState([]);
    //compute difference
    const [newPrev, diff] = computeUpdate(previousConns, props.conns);
    //trigger tooltips maybe
    useEffect(() => {
        if (diff.length > 0) {
            const timeout = setTimeout(() => {
                setPreviousConns(newPrev);
            }, 4000);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [diff.toString(), previousConns && previousConns.toString(), props.conns.toString()]);
    const diff_ = diff.filter((d) => d[0] == 'in' || !['idle', 'busy'].includes(d[2]));
    const failures = props.conns.filter((conn) => conn.status === 'failing');
    return (<span>
      <div className={`status-bar-conn-menu-btn${show ? '-open' : ''}`} ref={target} onClick={() => setShow(!show)}>
        {!props.isLoading ? <span id="statusbar-connection-count">{`${props.conns.length}`}</span> : <Spinner animation="border" size="sm"/>}
        <FontAwesomeIcon icon={faMicroscope} style={{ marginLeft: '4px', marginRight: '8px' }}/>
        <FontAwesomeIcon icon={show ? faTimes : faChevronUp}/>
      </div>
      <Overlay target={target.current} show={show} placement="top">
        {({ placement, arrowProps, show: _show, popper, ...props_ }) => (<div {...props_} style={{
                backgroundColor: 'rgba(0, 0, 0, 0.85)',
                padding: '2px 10px',
                color: 'white',
                borderRadius: 3,
                width: '500px',
                ...props_.style,
            }}>
            <MenuView conns={props.conns} show={show} url={props.url} diff={diff}/>
            <UpdatesView diff={diff_} show={!show}/>
          </div>)}
      </Overlay>
    </span>);
}
export function WorkspaceEditor(props) {
    if (!props.ws)
        return <div />;
    return (<div>
      
    </div>);
}
