import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './utils.css'

export default function IconButton({icon, onClick}) {
  /*Util to style button and handle interactivity*/ 
  return (
    <FontAwesomeIcon
      className='icon-button' 
      icon={icon}
      onClick={onClick}
    />
  )
}