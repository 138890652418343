import React, { useEffect, useState } from 'react';
import { Badge, Dropdown, ButtonGroup, Button } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import './Monitoring.css';
import Checkbox from '@material-ui/core/Checkbox';
import { Slider } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
const variants = {
    'warn': "warning",
    'info': "info",
    'fatal': "danger",
    'debug': "light"
};
const levelBadge = (lvl) => {
    console.log(lvl)
    switch (lvl) {
        case 'warn': return <Badge bg="warning">WARN</Badge>;
        case 'info': return <Badge bg="info">INFO</Badge>;
        case 'fatal': return <Badge bg="danger">ERROR</Badge>;
        case 'debug': return <Badge bg="light">DEBUG</Badge>
        default: return <Badge bg="dark">{lvl}</Badge>
    }
}

const levelBg = {
    info: 'info',
    warning: 'warning',
    fatal: 'danger',
    debug: 'dark'
}

const actorRepr = (actor) => {
    return <span style={{ width: '240px' }}>{actor}</span>;
};
function TimeWindow() {
    return (<div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginLeft: '16px', marginRight: '8px' }}>Time Window</span>
      <ButtonGroup aria-label="Basic example">
        <Button variant="secondary">Left</Button>
        <Button variant="secondary">Middle</Button>
        <Button variant="secondary">Right</Button>
      </ButtonGroup>
    </div>);
}
const refresh = (url, levelFilter, actorFilter, logsPerPage, page, setLogs, setNumPages) => {
    const actives = levelFilter ? Object.entries(levelFilter).filter(([l, a]) => a).map(([l, a]) => l) : [];
    const levels = actives.length > 0 ? "&levels=" + actives.reduce((prev, cur) => prev + "," + cur) : "";
    const actors = actorFilter ? Object.entries(actorFilter).filter(([_, active]) => active) : [];
    const actorsQuery = actors.length > 0 ? "&actors=" + actors.map(([k, _]) => k).reduce((prev, cur) => prev + "," + cur) : "";
    fetch(`${url}/logs?limit=${logsPerPage}&page=${page}${levels}${actorsQuery}`)
        .then((res) => res.json())
        .then((res) => {
        setLogs(res.logs);
        setNumPages(res.total);
    })
        .catch(err => { }); //indicate that something is offline...
};
const trueRefreshRates = [1, 2, 5, 10, 15, 20, 30, 60, 120];
function trueRefreshRate(falseRefreshRate) {
    return trueRefreshRates[falseRefreshRate / 10 - 1];
}
function refreshText(rate) {
    if (rate <= 0)
        return <strong style={{ width: '100%' }}>Not refreshing</strong>;
    return <strong style={{ width: '100%' }}>Refreshing every {trueRefreshRate(rate)}s</strong>;
}

export function Monitoring({active, api}) {
    const [logs, setLogs] = useState('[]')
    useEffect(() => {
        console.log("EFFECT")
        let url = api.url
        if(url === "" || url === undefined) return
        let socket = new WebSocket(`${url}/logs`.replace("http", "ws"))
        socket.onopen = () => {
            
            //socket.send(token)
        }
        socket.onmessage = ({data}) => {
            const log = JSON.parse(data)
            if(Object.keys(log).length > 0) {
                setLogs(prev => JSON.stringify([log].concat(JSON.parse(prev))))
            } // else we received a heartbeat or an error
        }
        socket.onclose = () => console.warn("Web Socket closed")
        socket.onerror = (err) => console.warn("Web Socket error", err)
        return () => {
            console.log("closing socket")
            socket.close()
        }
    }, [api.url])
    const logs_ = JSON.parse(logs)
    console.log(logs_)
    if(!active) return <span></span>
    return (
        <div>
            <table className='logstable'>
                <tbody>
                    {logs_ && logs_.splice(0, 20).map(({event_time, msg, level, tags}, idx) => (
                        <tr key={idx} className='logstable-row'>
                            <td><Badge bg={levelBg[level]}>{level}</Badge></td>
                            <td className='logs-event-time'>{event_time}</td>
                            <td className='logs-event-time'>
                                {tags.map(tag => <Badge bg="dark">{tag}</Badge>)}
                            </td>
                            <td>{msg.substring(0, 200)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
  }
  

export function Monitoring2(props) {
    const [logs, setLogs] = useState([]);
    const logsPerPage = 10;
    const [page, setPage] = useState(0);
    const [numPages, setNumPages] = useState(0);
    const [levelFilter, setLevelFilter] = useState({ 'fatal': true, 'warn': true, 'info': true, 'debug': true });
    const actives = Object.entries(levelFilter).filter(([l, a]) => a).map(([l, a]) => l); //TODO fix that if levelFilter is empty
    const [matchQuery, setMatchQuery] = useState('');
    const [actorFilter, setActorFilter] = useState();
    const [falseRefreshRate, setFalseRefreshRate] = useState(10);
    useEffect(() => {
        if (props.active) {
            props.menu?.set(["Logs"]);
        }
    }, [props.active]);
    const pageInc = (plus) => {
        if (plus) {
            setPage((page) => page + 1);
        }
        else {
            setPage((page) => page - 1);
        }
        //refresh(props.url, levelFilter, actorFilter, logsPerPage, page, setLogs, setNumPages)
    };
    const actorDependency = actorFilter ? Object.entries(actorFilter).map(([actor, active]) => active ? actor : '').reduce((prev, cur) => prev + cur, '') : '';
    useEffect(() => {
        if (!props.active)
            return;
        fetch(`${props.url}/logs/actors`).then((res) => res.json()).then((res) => {
            if (res) {
                const kv = res.actors.map((a) => [a, true]);
                //setActorFilter(Object.fromEntries(new Map(kv)))
            }
        });
        if (falseRefreshRate == 0) {
            refresh(props.url, levelFilter, actorFilter, logsPerPage, page, setLogs, setNumPages);
        }
        else {
            const refreshInterval = setInterval(refresh, trueRefreshRate(falseRefreshRate) * 1000, props.url, levelFilter, actorFilter, logsPerPage, page, setLogs, setNumPages);
            return () => {
                clearInterval(refreshInterval);
            };
        }
    }, [props.active, falseRefreshRate, props.url, page, levelFilter, actorDependency]);
    const filterVariant = () => {
        if (actives.length > 1)
            return 'primary';
        if (actives.length === 1)
            return variants[actives[0]];
        if (actives.length === 0)
            return 'secondary';
    };
    const filterName = () => {
        if (actives.length === 4)
            return 'All';
        if (actives.length === 1)
            return actives[0];
        if (actives.length === 0)
            return 'None';
        return actives.reduce((p, c) => p + "," + c);
    };
    const handleActorChange = (actor) => (event) => {
        let cpy = Object.assign({}, actorFilter);
        cpy[actor] = !cpy[actor];
        setActorFilter(cpy);
        //refresh(props.url, levelFilter, cpy, logsPerPage, page, setLogs, setNumPages)
    };
    const handleChange = (lvl) => (event) => {
        let cpy = Object.assign({}, levelFilter);
        cpy[lvl] = !cpy[lvl];
        setLevelFilter(cpy);
        //refresh(props.url, cpy, actorFilter, logsPerPage, page, setLogs, setNumPages)
    };
    const moduleQuery = actorFilter ? (<div style={{ display: 'flex', alignItems: 'center' }}>
      <strong className="logs-query-label">Module</strong>
      <Dropdown>
        <Dropdown.Toggle>
          Module
        </Dropdown.Toggle>
        <Dropdown.Menu className="bg-dark text-white" style={{ width: '240px' }}>
          {Object.entries(actorFilter).map(([actor, active]) => (<Dropdown.ItemText key={actor} className="bg-dark text-white" style={{ width: '240px' }}>
                  <Checkbox checked={active} onChange={handleActorChange(actor)}/>
                  {actorRepr(actor)}
                </Dropdown.ItemText>))}
        </Dropdown.Menu>
      </Dropdown>
    </div>) : undefined;
    const changeRefreshRate = (event, newValue) => {
        setFalseRefreshRate(newValue);
    };
    //<Form.Control type="text" value={matchQuery} className="bg-transparent text-white" onChange={(e: any) => queryUpdate(e.target.value)} />
    if (!props.active)
        return <span />;
    return (<div className="logs-view">
      <div className="logs-query">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <strong className="logs-query-label">Level</strong>
          <Dropdown>
            <Dropdown.Toggle variant={filterVariant()}>
              {filterName().toUpperCase()}
            </Dropdown.Toggle>
            <Dropdown.Menu className="bg-dark text-white">
              {Object.entries(levelFilter).map(([lvl, active]) => (<Dropdown.ItemText key={lvl} className="bg-dark text-white">
                  <Checkbox checked={active} onChange={handleChange(lvl)}/>
                  {levelBadge(lvl)}
                </Dropdown.ItemText>))}
            </Dropdown.Menu>
          </Dropdown>
          {moduleQuery}
        </div>
        <div className="logs-refresh">
          {refreshText(falseRefreshRate)}
          <Slider id="refresh-rate-slider" min={0} max={100} value={falseRefreshRate} step={10} onChange={changeRefreshRate}/>
        </div>
      </div>
      
      <ListGroup>
        {logs.map((log, i) => {
            const date = new Date(log.time);
            const time = `${date.toLocaleDateString()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
            return (<ListGroup.Item key={`log-${i}`} className="bg-dark text-white">
              {levelBadge(log.level)} <small><strong>{time}</strong></small> {log.text}
            </ListGroup.Item>);
        })}
      </ListGroup>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div>
          <FontAwesomeIcon icon={faChevronLeft} style={{}} onClick={() => pageInc(false)}/>
          <small className="pagination-text">{page + 1} out of {Math.ceil(numPages / logsPerPage)}</small>
          <FontAwesomeIcon icon={faChevronRight} onClick={() => pageInc(true)}/>
        </div>
      </div>
    </div>);
}
