import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Inputs.css'
import { Tooltip } from './Overlays'

export default function KVInput({label, value, type, info, update, width}) {
  //if type is in [text, email, password, ...]
  return (
    <div className="kv-input-container"
      style={width ? {width: width} : {}}
    >
      <div className='kv-input-label-group'>
        <label className='kv-input-label'>{label}</label>
        
        <Tooltip show={info}>
          <FontAwesomeIcon icon="info-circle" />
          <span>{info}</span>
        </Tooltip>
      </div>
      <input 
        className='kv-input'
        type="text" 
        value={value} 
        onChange={e => update(e.target.value)} />
    </div>
  )
}