import React, { useEffect, useState } from 'react';
//for classifierplugin
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Radio } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import * as _ from "lodash"
import './Plugins.css'
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowDimensions;
}
function stylePosition(pos) {
    if (!pos)
        return NaN;
    return (pos * 1000).toFixed(2);
}
function border(isAsbsestos, isError) {
    if (!isAsbsestos && !isError)
        return '0';
    const color = (isAsbsestos && isError) ? 'indianred' : 'greenyellow';
    return `2px solid ${color}`;
}
function centerOnPatch(dimensions, position, row, col) {
    let [x, y] = position;
    x = x - dimensions.width * (col - 1.5) / 4; //* 2 / 8//Math.floor((col - 1.5) / 2)//x - dimensions.width * (col / 4 - 3 / 8)//(col / 4 - 3 / 8) 
    y = y + (row - 1) * dimensions.height / 3;
    return { x: x, y: y };
}
const ASBESTOS_CLASSES = ['Clean', 'Amosit', 'Chrysotil', 'Dust'];
function confidenceColor(label, confidence) {
    let color = [255, 87, 51];
    if (['Amosit', 'Chrysotil'].includes(label))
        color = [80, 220, 100];
    return `rgba(${color[0]},${color[1]},${color[2]},${confidence * 100})`;
}
function probaToLabel(c) {
    let label = c.label;
    if (!c.label) {
        let cmax = 0;
        for (let i = 0; i < 4; ++i) {
            if (c.confidence[i] > cmax) {
                cmax = c.confidence[i];
                label = i;
            }
        }
    }
    return label;
}
function classificationToAnnotation(c) {
    let label = probaToLabel(c);
    return {
        label: label,
        isAsbestos: c.isAsbestos,
        patchidx: c.patchidx
    };
}
//TODO test that clicking calls updateData with the correct parameters
export const ClassifierPlugin = {
    Viewer: function ({sample_, updateData}) {
        let data = {}
        try {
            if(sample_ && sample_.output && sample_?.output['AsbestosClassifier'])
            data = JSON.parse(sample_?.output['AsbestosClassifier'] ?? '')
        } catch(err) {
            console.warn("could not get sample data")
        }
        console.log(sample_, data)
        const sample = (data?.patches ?? [])
        const [annotation, setAnnotation] = useState({})
        const [seqNum, setSeqNum] = useState(-1)
        const { height, width } = useWindowDimensions()
        //TODO add test case that checks that all errors are properly reported
        useEffect(() => {
            setAnnotation(data?.annotations ?? {})
        }, [sample_?.metadata.seqNum])
        const toggleError = (patchidx, label) => () => {
            if (annotation) {
                const newVal = {
                    label: label,
                    isAsbestos: [2, 3].includes(label),
                    patchidx: patchidx
                };
                setAnnotation(prev => {
                    const update = Object.assign({}, prev, { [patchidx.toString()]: newVal });
                    updateData({
                        output: {
                            annotations: update
                        }
                    })
                    return update;
                });
            }
        };
        if (!sample || sample.length < 1)
            return ''
        //const imgStyle: React.CSSProperties = {} //parameters.review.patchPadding.enabled ? {margin: '2px', borderRadius: '2px'} : {}
        return (<div>
          {_.chunk(sample, 4).map((row, r) => (<div style={{ textAlign: 'center', margin: '0 auto', justifyContent: 'center', display: 'flex' }} key={`row-${r}`}>
              <div style={{ display: 'flex' }}>
                {row.map((classification, i) => {
                    let color = {};
                    let label = probaToLabel(classification);
                    if (classification.patchidx in annotation) {
                        label = annotation[classification.patchidx].label;
                    }
                    const isError = label !== probaToLabel(classification);
                    if (classification.isAsbestos)
                        color = Object.assign({}, color, { background: "rgba(127,255,0, 0.3)" });
                    if (isError)
                        color = Object.assign({}, color, { border: "2px solid indianred" });
                    const center = { x: 0, y: 0 }; //props.sample?.dimensions ? centerOnPatch(props.sample.dimensions!, props.sample.position, r, i) : undefined
                    const move = () => {
                        //fetch(`${props.url}/stages/${props.connectionId}?x=${center!.x}&y=${center!.y}`)//.then(res => res.json())
                    }
                    return (
                    <div className="patch-container" key={`patch-${classification.patchidx}`}>
                      <img 
                        alt="patch" 
                        id={`patch-${4 * r + i}`} 
                        key={`img-${i}`} 
                        src={`data:image/jpeg;base64, ${classification.b64}`} 
                        style={{}} 
                        height={(height - 88 - 16) / 3}/>
                      <span className="patch-overlay" style={color}/>
  
                        {center && <div className="patch-ctrl-overlay" key={`patch-ctrl-overlay-${classification.patchidx}`}>
                          <div className="patch-confidence-container">
                            {classification.confidence.map((p, cls) => (
                              <div className="single-confidence-container">
                                <div style={{ flexDirection: 'column', display: 'flex', backgroundColor: confidenceColor(ASBESTOS_CLASSES[cls], p) }}>
                                  <small className="confidence-class-title">{ASBESTOS_CLASSES[cls]}</small>
                                  <strong>{(p * 100).toFixed(0)}</strong>
                                </div>
                                <Radio 
                                data-testid={`patch${classification.patchidx}-radio-${cls}`}
                                  id={`patch${classification.patchidx}-radio-${cls}`} 
                                  checked={cls === label} 
                                  onClick={toggleError(classification.patchidx, cls)}/>
                              </div>
                            ))}
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <OverlayTrigger trigger={["hover", "focus"]} overlay={<Tooltip id={"ptctt-" + i}>
                                  Center @({(center.x * 1000).toFixed(2)},{(center.y * 1000).toFixed(2)})
                              </Tooltip>}>
                            <FontAwesomeIcon className="patch-center-btn" icon={faBullseye} onClick={move}/>
                          </OverlayTrigger>
                          </div>
                        </div>}
                    </div>);
                })}
              </div>
            </div>))}
        </div>);
    },
    Toolbar: function ClassifierToolbar(props) {
        return (<div>
        Pass
      </div>);
    }
};
export const SegmentationPlugin = {
    Toolbar: function SegmentationToolbar(props) {
        return (<div style={{ width: '100%' }}>
        <h5>Asbestos Segmentation</h5>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <label style={{ color: 'gray' }}>Number of fibers</label>
          <span>{props.metadata["numberOfFibers"]}</span>
        </div>
      </div>);
    },
    Viewer: function SegmentationViewer({data, metadata, workspaceId, api, dimensions, sample}) {
        const { height, width } = useWindowDimensions()
        const imageDimensions = {width: 1024, height: 768}
        const margins = {x: 450, y: 120}
        const ratio = (imageDimensions.width) / (imageDimensions.height)
        const windowRatio = (width - margins.x) / (height - margins.y)
        let displayHeight
        let displayWidth
        if(windowRatio > ratio) { // i.e. window limited in height (display is "long" instead of high)
            displayHeight = height - margins.y
        } else {
            displayWidth = width - margins.x
        }
        const pos = (sample && sample.metadata) ? sample.metadata.position : [0, 0]
        return (
            <div className='generic-plugin-overlay'>
                <svg width={displayWidth} height={displayHeight} viewBox='0 0 1024 768'  >
                    {metadata && metadata.centers && metadata.centers.map(center => (
                        <g>
                            <circle
                                onClick={() => {
                                    const x = (dimensions ? dimensions.width : 1) * -1 * (center[0] - 1024/2) / 1024 + pos[0]
                                    const y = (dimensions ? dimensions.height : 1) * (center[1] - 768/2) / 768 + pos[1]
                                    api.get(`/workspaces/${workspaceId}/stage?x=${x}&y=${y}`)
                                }}
                                className='seg-plugin-interactive-icon' 
                                cx={center[0]} 
                                cy={center[1]} 
                                r="10" 
                                opacity={0.8} />
                        </g>
                    ))}
                </svg>
                {<img 
                    alt="patch" 
                    src={`data:image/jpeg;base64, ${data}`} 
                    style={{}} height={displayHeight} width={displayWidth}/>}
            </div>
        )
        /*
        
            <img 
                alt="patch" 
                src={`data:image/jpeg;base64, ${data}`} 
                style={{}} height={displayHeight} width={displayWidth}/>
        */ 
        /*const data = props.data ? props.data['patches'] : undefined;
        const { height, width } = useWindowDimensions();
        if (!data)
            return ''
        return (<div>
        {_.chunk(data, 4).map((row, k) => (<div style={{ textAlign: 'center', margin: '0 auto', justifyContent: 'center', display: 'flex' }} key={`row-${k}`}>
            <div style={{ display: 'flex' }}>
              {row.map((sample, k1) => (<div className="patch-container" key={`patch-${k}${k1}`}>
                  <img alt="patch" id={`patch-${4 * k + k1}`} key={`img-${k}-${k1}`} src={`data:image/jpeg;base64, ${sample.jpegLabelled}`} style={{}} height={(height - 88 - 16) / 3}/>
                </div>))}
            </div>
          </div>))}
      </div>);*/
    }
};
