import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import './Tab.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
export default function Tab(props) {
    const closeIcon = () => {
        return !props.isActive ? <span /> : <FontAwesomeIcon icon={faTimesCircle} className='close-icon' onClick={() => props.close()}/>;
    };
    const renderOverlay = (myProps) => (<Tooltip style={{ zIndex: 400 }} {...myProps}>
      <strong>{props.overlay}</strong>
    </Tooltip>);
    const tab = () => (<div className='tab-active'>
      <span className={`tab-name-${props.isActive ? '' : 'in'}active`} onClick={() => props.open()}>
        {props.name}
      </span>
      {closeIcon()}
    </div>);
    if (props.overlay !== undefined)
        return (<OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderOverlay} trigger="hover">
      {tab()}
    </OverlayTrigger>);
    return tab();
}
