import React, { useState } from 'react'
import './Tabs.css'

export function Tabs({tabs, children}) {
  const [visibleTab, setVisibleTab] = useState(0)
  return (
    <div className="tabs-container">
      <div className="tabs-header">
        {tabs.map((tab, i) => (
          <button 
            key={i} 
            className={`tabs-button${(i === visibleTab) ? '-reverse' : ''}`} 
            onClick={() => setVisibleTab(i)}>
            {tab}
          </button>
        ))}
      </div>
      <div className="tabs-content">
        {children[visibleTab]}
      </div>
    </div>
  )
}