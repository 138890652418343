import React, { useState, useEffect, useRef, useContext } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { auth0Ctx, ctx } from './contexts'
import './App.css';
import './components/tabs/Monitoring.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import Tab from './components/utils/Tab';
import Background from './components/utils/Background';
import Settings from './components/tabs/Settings';
import Home from './components/tabs/Home';
import StatusBar from './components/StatusBar';
import { OverlayTrigger, Overlay, Button, Spinner } from 'react-bootstrap';
import Review from './components/tabs/Review';
import { ClickableTooltip } from './components/utils/Overlays'
import { Archive } from './components/tabs/archive/Archive';
import { TextField } from '@material-ui/core';
import { useCookies } from 'react-cookie';
import { faHome, faCog, faArrowRightFromBracket, faPoll, faTable, faArchive, faUserCircle, faInfoCircle, faImages, faEject, faRotateRight, faInfo, faFolderOpen, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { faChevronDown, faCircleQuestion, faIdCardClip, faPlay, faPlus, faXmark, faGlobe } from '@fortawesome/free-solid-svg-icons'
import logo from './amiscanlogo.svg';
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill'
import { Monitoring } from './components/tabs/Monitoring'

const EventSource = NativeEventSource || EventSourcePolyfill

const levelBg = {
  info: 'info',
  warning: 'warning',
  fatal: 'danger',
  debug: 'dark'
}
library.add(faHome, faCog, faPoll, faTable, faArchive, faUserCircle, faInfoCircle)
library.add(faCircleQuestion, faXmark, faChevronDown, faPlay, faPlus, faIdCardClip)
library.add(faArrowRightFromBracket, faImages, faEject, faRotateRight, faInfo, faFolderOpen, faTrashAlt, faGlobe)

/*
function request(url, options) {
  return fetch(url, Object.assign({}, options, {
    headers: Object.assign({}, options.headers, {
      'Authorization': 'Bearer'
    })
  }))
}*/

function LoginMenu(props) {
    const [user, setUser] = useState({ userid: 'default' });
    const [isLogin, setIsLogin] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);
    const [state, setState] = useState({ isActing: false });
    const alignLeft = { display: 'flex', alignContent: 'flex-end', width: '100%', justifyContent: 'flex-end', marginBottom: '4px' };
    const act = () => {
        setState({ isActing: true });
        if (isLogin) { // get cookies
            fetch(`${props.url}/users/${user.userid}`, { credentials: 'include' }).then((res) => res.json()).then((res) => {
                setState({ isActing: false });
            }).catch((err) => {
                setState({ isActing: false, failure: err.toString() });
            });
        }
        else {
            console.log(`${props.url}/users`);
            fetch(`${props.url}/users`, {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({ userid: user.userid })
            }).then(res => res.json()).then(res => {
                setState({ isActing: false });
            }).catch((err) => {
                setState({ isActing: false, failure: err.toString() });
            });
        }
    };
    if (!props.show)
        return <span />;
    return (<div>
      <div style={alignLeft} onClick={() => setIsLogin(!isLogin)}>
        <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>{isLogin ? 'Sign In' : 'Login'}</span>
      </div>
      <TextField variant="outlined" size="small" label="Username" style={{ marginBottom: '8px', marginTop: '4px' }} helperText={state.failure} error={state.failure !== undefined} fullWidth/>
      <div style={alignLeft}>
        <Button size="sm" onClick={act} disabled={state.isActing} variant={state.isActing ? 'success' : (state.failure ? 'danger' : 'dark')}>
          {state.isActing ? <Spinner animation="border" size="sm"/> : ''}
          {isLogin ? 'Login' : 'Sign In'} 
        </Button>
      </div>
    </div>);
}
function UserMenu(props) {
    const [user, setUser] = useState({ username: 'default' });
    const [status, setStatus] = useState({});
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [cookies, setCookie, removeCookie] = useCookies(['userid']);
    const loggedIn = 'userid' in cookies;
    return (<div className="navbar-container">
    <span>{user.username}</span>
     <span ref={target}>
      <FontAwesomeIcon icon={faUserCircle} style={{ width: '30px', height: '30px', marginLeft: '4px', cursor: 'pointer' }} onClick={() => setShow(!show)}/>
     </span>
      <Overlay target={target.current} show={show} placement="bottom">
        {({ placement, arrowProps, show: _show, popper, ...props_ }) => (<div {...props_} style={{
                backgroundColor: 'rgba(130, 130, 130, 0.85)',
                padding: '2px 10px',
                color: 'white',
                borderRadius: 3,
                width: '250px',
                ...props_.style,
            }}>
            <LoginMenu url={props.url} show={!loggedIn}/>
          </div>)}
      </Overlay>
    </div>);
}


const call_ = (url, token) => (route, method, handlers, data) => {
  let request = {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    method: method,
    mode: 'cors'
  }
  if(data && data.body) {
    request.body = data.body
  }
  let res = fetch(`${url}${route}`, request).then(res => {
    return res.json()
  })
  if(handlers && handlers.json) {
    res = res.then(handlers.json)
  }
  if(handlers && handlers.error) {
    res = res.catch(handlers.error)
  } else { //we do this to silence network errors in the console
    res = res.catch(err => {})
  }
  return res
}


export function Main({api, show, reset, refData, events, liveLogs}) {
  /**
   * - reset: resets the url (i.e. exits network and goes back to dashboard)
   * - refData: slowly changing data {manufacturers}
   * - events: {requests: [], reports: [], workspaces: []}
   */
    const [activeTab, setActiveTab] = useState('')
    const [tabNames, setTabNames] = useState([])
    const [tabs, setTabs] = useState([])
    const [reviewedAnalysis, setReviewedAnalysis] = useState()
    const [live, setLive] = useState(false)
    const [mainProcessFeed, setMainProcessFeed] = useState([])
    const [menu, setMenu] = useState()
    const [activeMenu, setActiveMenu] = useState()
    const openTab = (name, view) => { setActiveTab(name) }
    const closeTab = (name) => { }
    const handleRun = () => { openTab('Home', null) }
    useEffect(() => {
        openTab('Home', null)
    }, [])
    const theme = {
      colors: {
        sidebar: '',
        navbar: '',
        background: ''
      }
    }
    const tabCtrl = { open: openTab, close: closeTab, setAnalysis: setReviewedAnalysis }
    console.log(activeTab)
    if(!show) return <span />
    return (
      <React.Fragment>
        <div className="navbar">
          <div className="navbar-container">
            <div className='navbar-left'>
              <img 
                src={logo} 
                alt='logo'
                height="40px"
              />
              <span className="navbar-title">
                {activeTab}
              </span>
              {tabNames.map((tab, i) => {
                  const isActive = tab === activeTab;
                  return <Tab 
                    key={tab} 
                    isActive={isActive} 
                    overlay={activeTab.startsWith('Review') && !tab.startsWith('Review') ? 'Leaving the Review tab will lose all unsaved work!' : undefined} 
                    name={tab} 
                    close={() => closeTab(tab)} 
                    open={() => !isActive && setActiveTab(tab)}
                  />
              })}
              {menu?.map((item, i) => (
                <span 
                  key={`menu-item${i}`} 
                  className='navbar-menu-item'
                  style={item === activeMenu ? { backgroundColor: "gray" } : {}} 
                  onClick={() => setActiveMenu(item)}
                >
                  {item}
                </span>
              ))}
            </div>
            <div className='navbar-right'>
              <FontAwesomeIcon 
                className='sidebar-icon'
                icon="arrow-right-from-bracket"
                onClick={() => {
                  setLive(false)
                  reset()
                }}
              />
            </div>
          </div>
        </div>
        <div className="sidebar">

          <div className="sidebar-top">
            <FontAwesomeIcon className='sidebar-icon' icon="home" onClick={() => {
              openTab('Home', null)
            }}/>
            <FontAwesomeIcon className='sidebar-icon' icon="archive" onClick={() => {
              openTab('Archive', null)
            }}/>
            
          </div>
          <div className="sidebar-bottom">
            <FontAwesomeIcon 
              className='sidebar-icon' 
              icon="poll" 
              onClick={() => {
                  openTab('Metrics', null);
              }}/>
            <FontAwesomeIcon className='sidebar-icon' icon="cog" onClick={() => openTab('Settings', null)}/> 
          </div>
        </div>
        <Background>
          <Home 
            live={live} 
            tabCtrl={tabCtrl} 
            api={api}
            events={events}
            active={activeTab === 'Home'} 
            workspaces={events.workspaces} 
            menu={{ active: activeMenu, set: setMenu }}
          />
          <Archive 
            api={api}
            active={activeTab === 'Archive'} 
            setAnalysis={setReviewedAnalysis} 
            tabCtrl={tabCtrl} 
            menu={{ active: activeMenu, set: setMenu, setTab: setActiveMenu }}/>
          <Settings 
            mainFeed={mainProcessFeed} 
            tabCtrl={tabCtrl} 
            api={api}
            online={live}
            active={activeTab === 'Settings'} 
            refData={{manufacturers: refData.manufacturers}}
            menu={{ active: activeMenu, set: setMenu }}/>
          <Monitoring 
            api={{url: api.url}}
            active={activeTab === 'Metrics'} 
            menu={{ active: activeMenu, set: setMenu }}
          />
          <Review 
            api={api}
            analysis={reviewedAnalysis} 
            close={() => openTab('Home', null)} 
            active={activeTab === 'Review'} 
            menu={{ active: activeMenu, set: setMenu, setTab: setActiveMenu }}/>
        </Background>
        <StatusBar 
          api={api}
        />
    </React.Fragment>
  )
}

/** This is a wrapper for the Main component, that creates 
 * the API, fetches static data, sets up streamers. All data
 * that is needed in multiple places is centralized this way
  */
export function Platform({url, token, show, reset}) {
  console.log("render platform")

  const [state, setState] = useState({
    events: {
      requests: [], reports: [], workspaces: []
    },
    manufacturers: undefined, version: undefined
  })
  const call = call_(url, token)
  const api = {
    isLive: () => true,//live,
    call: call,
    url: url, //mostly for reporting purposes. not meant to be used directly
    get: (route, handlers) => call(route, "GET", handlers), // only handles JSON
    text: (route, onText) => {
      let request = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        method: 'GET',
        mode: 'cors'
      }
      fetch(`${url}${route}`, request)
        .then(res => res.text())
        .then(txt => onText(txt))
        .catch(err => console.warn(err))
    },
    blob: (route, onData) => {
      let request = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/zip'
        },
        method: 'GET',
        mode: 'cors'
      }
      fetch(`${url}${route}`, request)
        .then(res => res.blob())
        .then(data => onData(data))
        .catch(err => console.warn(err))
    },
    post: (route, body, handlers) => call(route, "POST", handlers, {body: body}),
    delete: (route, handlers) => call(route, "DELETE", handlers),
    streamEvents: (route, stateListener) => {
      if(url === "" || url === undefined) return
      let socket = new WebSocket(`${url}${route}`.replace("http", "ws"))
      socket.onopen = () => {
        socket.send(token)
      }
      socket.onmessage = ({data}) => {
        const {events, isAuthenticated} = JSON.parse(data)
        if(events !== undefined) {
          console.log("EVENTS", events)
          stateListener(events)
        }
        if(isAuthenticated === false) {
          socket.send(token)
        }
      }
      socket.onclose = () => console.warn("Web Socket closed")
      socket.onerror = (err) => console.warn("Web Socket error", err)
      return socket
    },
    stream: (route, handlers) => {
      if(url === "" || url === undefined) return
      let socket = new WebSocket(`${url}${route}`.replace("http", "ws"))
      socket.onopen = () => {
        socket.send(token)
      }
      socket.onmessage = ({data}) => {
        const msg = JSON.parse(data)
        if(Object.keys(msg).length > 0) {
          handlers.onMessage(msg)
        }
      }
      socket.onclose = () => console.warn("Web Socket closed")
      socket.onerror = (err) => console.warn("Web Socket error", err)
      return socket
    }
  }
  useEffect(() => {
    console.log("API effect")
    if(!url) {
      console.log("no url")
      return
    }
    api.get("/microscopes/manufacturers", {
      json: (v) => {
        setState(prev => Object.assign({}, prev, {manufacturers: v}))
      }
    })
    const eventsHook = api.streamEvents("/events", events => {
      setState(prev => {
        console.log("state update (events)", 'state', state, 'previous', prev, 'new', Object.assign(prev, {}, events))
        return Object.assign({}, prev, {events: events})
      })
    })
    return () => {
      console.log("cleaning up platform hooks")
      eventsHook.close()
    }
  }, [url, token])
  console.log(state)
  return <Main 
    api={api} 
    reset={reset} 
    show={show} 
    events={state.events}
    refData={{
      manufacturers: state.manufacturers
    }}
  />
}

 
function Pill({children}) {
  return (
    <div className='pill'>
      {children}
    </div>
  )
}

function Login({show, setAuth}) {
  const {isLoading, error, isAuthenticated, loginWithRedirect, user} = useAuth0()
  console.log(isAuthenticated)
  useEffect(() => {
    setAuth(isAuthenticated)
  } ,[isAuthenticated])
  /*if(isLoading) return (
    <button>Loading</button>
  )*/
  //if(!isAuthenticated) 
  if(isAuthenticated) return <span></span>
  return (
    <div className='login-container'>
      <div className='login-card'>
        <div className='login-card-header'>
          <span className='login-card-title'>Amiscan</span>
          <img src={logo} height="40px"/>
        </div>
        {!isAuthenticated && (
          <button 
            className='login-button'
            onClick={() => {
              loginWithRedirect().then((r) => console.log('done', r)).catch(e => console.log('err', e))
            }}
          >
            <FontAwesomeIcon 
              icon="id-card-clip" 
              className='button-icon'
            />
            {isLoading && 'Loading'}
            {!isLoading && 'Login'}
          </button>
        )}
        <div className='login-card-footer'>
          <Pill>BETA</Pill>
        </div>
      </div>
    </div>
  )
}

function ClientCard({name, select}) {
  return (
    <div className='network-client-card'>
      <span className='network-client-card-title'>{name}</span>
      <input type="radio" onClick={select} />
    </div>
  )
}

function Dropdown({name, values, select}) {
  const [value, setValue] = useState()
  useEffect(() => setValue(values[0]), [])
  useEffect(() => select(value), [value])
  return (
    <div className='key-value-form'>
      <label>{name}</label>
      
      <div className='dropdown'>
        <button className='dropdown-root'>
          {value}
          <FontAwesomeIcon 
            icon="chevron-down" 
            id="dropdown-icon"
          />
        </button>
        <div className='dropdown-content'>
          {values.map(v => (
            <div 
              className='dropdown-element'
              onClick={() => setValue(v)}
            >
              {v}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function GenericInput({name, inputType, onChange}) {
  const [value, setValue] = useState()
  useEffect(() => onChange(value), [value])
  return (
    <div className='key-value-form'>
      <label>{name}</label>
      <input 
        type={inputType} 
        value={value} 
        className='textual-input'
        onChange={e => setValue(e.target.value)} />
    </div>
  )
}

function GenericForm({form, onChange}) {
  const {name, values, readableName} = form
  if(Array.isArray(values)) 
    return <Dropdown 
      name={readableName}
      values={values}
      select={onChange}
    />
  return <GenericInput 
    name={readableName}
    onChange={onChange}
  />
}

function FullPageOverlay({show, zIndex, children}) {
  if(!show) return <span></span>
  return (
    <div className='full-page-overlay-container' style={{zIndex: zIndex}}>
      {children}
    </div>
  )
}

function validateNetworkData() {

}

function NetworkForm({show, close, refresh}) {
  const [client, setClient] = useState()
  const [network, setNetwork] = useState({})
  const [status, setStatus] = useState('')
  const [state, setState] = useState({})
  const {user} = useAuth0()
  const url = process.env.REACT_APP_ADMIN_URL
  const vpnClients = [
    {
      name: 'netExtender',
      humanReadableName: 'Net Extender', 
      description: 'SonicWall client',
      form: [
        {'readableName': 'Domain', 'name': 'domain', 'values': ['LocalDomain']},
        {'name': 'user', 'readableName': 'User', 'values': 'text'},
        {'readableName': 'Password', 'name': 'password', 'values': 'password'},
        {'readableName': 'Server IP', 'name': 'server_ip', 'values': 'text'},
        {'readableName': 'Server Port', 'name': 'server_port', 'values': ''}
      ]
    }
  ]
  const update = (value, set) => {
    set(p => Object.assign({}, p, value))
  }
  const createNetwork = () => {
    update({'status': 'uploading'}, setState)
    //TODO validate network
    const data = JSON.stringify({
      name: state.name,
      owner_email: user.email
    })
    console.log('uploading', data)
    fetch(`${url}/networks`, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: data
    }).then(res => {
      update({'status': 'done'}, setState)
      refresh()
    })
      .catch(err => update({'status': 'error', 'error': err}, setState))
  }//TODO fix width
  if(!show) return ''
  return (
    <div>
      <div className='network-form-container'>
        <div className='centering-container'>
          <FontAwesomeIcon 
            id='close-network-form'
            onClick={close} 
            icon="xmark"
          />
        </div>
        <h2>New Network</h2>
        <div className='key-value-form'>
          <label>Name</label>
          <input 
            className='textual-input'
            type="text" 
            value={state.name} 
            onChange={e => setState(Object.assign({}, state, {name: e.target.value}))} 
          />
        </div>
        <h3>VPN</h3>
        {vpnClients.map(c => <ClientCard name={c.humanReadableName} description={c.description} select={() => setClient(c)} />)}
        {client && client.form.map(f => (
          <GenericForm 
            form={f} 
            onChange={v => setNetwork(Object.assign({}, network, {[f.name]: v}))} 
          />
        ))}
        <span className={state.status === 'error' ? 'network-form-error' : 'hidden'}>{state.error && state.error.message}</span>
        <button onClick={createNetwork} className='network-form-create-button'>
          {state.status === 'uploading' && <Spinner/>}
          Create
        </button>
      </div>
    </div>
  )
}

function NetworkLauncher({network, launch}) {
  console.log("network", network)
  return (
    <div className="network-launcher">
      <div className='network-launcher-title'>{network.name}</div>
      <div className='network-launcher-content'>{network.vpn && Object.keys(network.vpn)[0]}</div>
      <div className='network-launcher-action'>
        <button 
          onClick={() => launch(network)}
        >
          Launch <FontAwesomeIcon icon="play" id="launcher-play" />
        </button>
      </div>
    </div>
  )
}

//The only way to close the dashboard is to select a network (=API endpoint) - by design
function Dashboard({launch, setToken}) {
  const {isAuthenticated, user, getAccessTokenSilently} = useAuth0()
  const [networks, setNetworks] = useState([])
  const [state, setState] = useState({showForm: false, address: ""})
  //const {url} = useContext(ctx)
  const url = process.env.REACT_APP_ADMIN_URL
  const [cookies, setCookie, removeCookie] = useCookies(['amiscan'])
  const getNetworks = () => {
    if(!isAuthenticated || !user.email) return
    console.log(`${url}/networks/${user.email}`)
    fetch(`${url}/networks/${user.email}`)
    .then(res => res.json())
    .then(setNetworks)
    getAccessTokenSilently({audience: 'https://api.amiscan.xyz/', scope: 'admin'})
      .then(token => {
        setToken(token)
      })
  }
  useEffect(() => {
    getNetworks()
  }, [isAuthenticated && user.email])
  if(!isAuthenticated) return <span/>
  return (
    <div className='dashboard-container'>
      <div className='dashboard-title-bar'>
        <span className='dashboard-header'>Dashboard</span>
        <FontAwesomeIcon 
          id='dashboard-help-button'
          icon="circle-question" 
        />
      </div>
      <div>
        <div className='networks-title-bar'>
          <span className='networks-title'>Networks</span>
          <ClickableTooltip>
            <FontAwesomeIcon icon="plus" 
              id='network-create-button'
              onClick={() => setState(Object.assign({}, state, {showForm: true}))}
            />
            <NetworkForm 
              show={state.showForm} 
              close={() => setState(Object.assign({}, state, {showForm: false}))} 
              refresh={() => {}} 
            />
          </ClickableTooltip>
          
        </div>
        <div className="custom-network">
          <h3>Address</h3>
          <input 
            type="text" 
            value={state.address} 
            onChange={e => setState(Object.assign({}, state, {address: e.target.value}))}
          />
          <Button onClick={() => launch({endpoint: state.address, network_id: null})}>
            Go
          </Button>
        </div>
        <div className='network-launchers-container'>
          {networks.map(net => (
            <NetworkLauncher 
              key={net.name}
              network={net} 
              launch={launch}
            />))}
        </div>
      </div>
      
    </div>
  )
}

const App = () => {
  const {domain, clientId} = useContext(auth0Ctx)
  const [url, setUrl] = useState() // url of API endpoint
  const [token, setToken] = useState()
  const [dashboard, setDashboard] = useState(true)
  const [isAuth, setIsAuth] = useState(false)
  useEffect(() => {
    //Sets default network during first connection so that new users do not see the dashboard
    if(token !== undefined) {
      setUrl('http://localhost:8888')
      setDashboard(false)
    }
  }, [token])
  return (
    <div>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        redirectUri={window.location.origin}
        audience={'https://api.amiscan.xyz/'}
        scope="admin"
      >
        <Login show={!isAuth} setAuth={setIsAuth} />
        <FullPageOverlay show={dashboard && isAuth} zIndex={3000} >
          <Dashboard 
            setToken={setToken}
            launch={({endpoint, network_id}) => {
              setUrl(endpoint) // TODO change in db the url of the demo network
              setDashboard(false)
            }}  />
        </FullPageOverlay>
        <Platform 
          url={url} 
          reset={() => {
            setDashboard(true)
            setUrl(undefined)
          }}
          show={isAuth} 
          token={token} />
      </Auth0Provider>
    </div>
  )
}

export default App
