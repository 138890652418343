import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import './Review.css';
import Switch from '@material-ui/core/Switch';
import { Line } from 'react-chartjs-2';
import DistributionView from './review/DistributionView';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { TextField, CircularProgress, Checkbox } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStepForward, faStepBackward, faSignOutAlt, faCalendarAlt, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { ClassifierPlugin, SegmentationPlugin } from './Plugins';

const darkTheme = createTheme({
  palette: {
    type: "dark"
  }
})

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowDimensions;
}
function plot(summary) {
    if (!summary)
        return <span></span>;
    return (<Line data={{
            labels: summary.map((s) => s.id),
            datasets: [
                {
                    label: 'Score',
                    data: summary.map((s) => s.score * 1000),
                    fill: false,
                    backgroundColor: 'rgb(255, 99, 132)',
                    borderColor: 'rgba(255, 99, 132, 0.2)',
                },
            ],
        }} width={1} options={{ maintainAspectRatio: false }} height={100}/>);
}
function Preview({analysis, start, api}) {
    //const positives = props.analysis.output//.filter((outcome: Outcome) => outcome.result)
    const [summary, setSummary] = useState([])
    const [state, setState] = useState({
        synced: true,
        annotated: true,
        fibercnt: false
    })
    /*useEffect(() => {
        //TODO change to api call, rename -/reportsm 
        api.get("/reports/{analysis.analysisId}", {
            json: (res) => {
                console.log(res.samples);
                if (res.samples) {
                    setSummary(res.samples);
                }
            }
        })
    }, [props.analysis.analysisId]);*/
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    let time = '';
    let dateStr = '';
    if (analysis) {
        const date = new Date(analysis.date);
        time = `${date.getHours().toString().padStart(2, '0')}h${date.getMinutes().toString().padStart(2, '0')}`;
        dateStr = `${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`;
    }
    //const showElapsedTime = props.analysis?.profile && 'elapsed_time' in props.analysis?.profile
    //{showElapsedTime ? <span><FontAwesomeIcon icon={faStopwatch} /> {` ${props.analysis?.profile['elapsed_time'].toPrecision(3)}s`}</span> : ''}
    //<span>Synchronize Stage <Switch checked={state.synced} onChange={handleChange} name="synced"/> </span>
    return (<div style={{ textAlign: 'center', verticalAlign: 'center' }}>

      <h1>{analysis ? analysis.request.title : 'Loading...'}</h1>
        <small>
          <FontAwesomeIcon icon={faCalendarAlt}/> {dateStr} {time}
          
        </small>
      <h2>{analysis.metadata.length} samples</h2>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        
      </div>
      <div style={{ position: 'absolute', bottom: '40px', right: '16px', display: 'inline' }}>

        <Button 
          onClick={() => {
            start(state, 'positives');
          }}
        >
          Review
        </Button>
      </div>
    </div>);
}
function stylePosition(pos) {
    if (!pos)
        return NaN;
    return (pos * 1000).toFixed(2);
}
function border(isAsbsestos, isError) {
    if (!isAsbsestos && !isError)
        return '0';
    const color = (isAsbsestos && isError) ? 'indianred' : 'greenyellow';
    return `2px solid ${color}`;
}
function centerOnPatch(dimensions, position, row, col) {
    let [x, y] = position;
    x = x - dimensions.width * (col - 1.5) / 4; //* 2 / 8//Math.floor((col - 1.5) / 2)//x - dimensions.width * (col / 4 - 3 / 8)//(col / 4 - 3 / 8) 
    y = y + (row - 1) * dimensions.height / 3;
    return { x: x, y: y };
}
const ASBESTOS_CLASSES = ['Clean', 'Amosit', 'Chrysotil', 'Dust'];
function confidenceColor(label, confidence) {
    let color = [255, 87, 51];
    if (['Amosit', 'Chrysotil'].includes(label))
        color = [80, 220, 100];
    return `rgba(${color[0]},${color[1]},${color[2]},${confidence * 100})`;
}
function probaToLabel(c) {
    let label = c.label;
    if (!c.label) {
        let cmax = 0;
        for (let i = 0; i < 4; ++i) {
            if (c.confidence[i] > cmax) {
                cmax = c.confidence[i];
                label = i;
            }
        }
    }
    return label;
}
function classificationToAnnotation(c) {
    let label = probaToLabel(c);
    return {
        label: label,
        isAsbestos: c.isAsbestos,
        patchidx: c.patchidx
    };
}
function samplePosition(sample) {
    if (!sample) {
        return '';
    }
    return `${(sample.position[0] * 1000).toFixed(2)},${(sample.position[1] * 1000).toFixed(2)}`;
}
function getPluginMetadata(sample) {
    if (!sample)
        return {};
    if (!sample.metadata)
        return {};
    if (!sample.metadata.plugins)
        return {};
    if (!('AsbestosSegmentation' in sample.metadata.plugins))
        return {};
    return sample.metadata.plugins['AsbestosSegmentation'];
}

export function SampleReview({analysis, behavior, finish, api, url}) {
    const samples = analysis.metadata;
    const initState = { sampleIdx: 0, sample: undefined, errors: {}, currentErrors: new Set(), currentFibers: 0, fibers: {}, fibersDefault: 0 };
    const [state, setState] = useState(initState);
    console.log("state", state)
    const [params, setParams] = useState({ showClassification: true });
    const isLast = state.sampleIdx + 1 > samples.length;
    const updatePluginData = (pluginOutputUpdate) => {
      api.post(
        `/samples/${state.sample.metadata.sampleId}/plugins/AsbestosClassifier`, 
        JSON.stringify(pluginOutputUpdate), 
        {
        json: (j) => console.log(j),
        error: (err) => {
          alert("[WARNING] Could not upload annotation")
        }
      })
    }
    useEffect(() => {
        setState(prev => {
            if (!prev.sample)
                return Object.assign({}, prev, { sample: undefined });
            const sampleId = prev.sample.seqNum;
            const err = prev.currentErrors.size > 0 ? { errors: Object.assign({}, prev.errors, { [sampleId]: Array.from(prev.currentErrors) }) } : {};
            const fib = Object.assign({}, prev.fibers, { [sampleId]: prev.currentFibers });
            return Object.assign({}, prev, { sample: undefined, fibers: fib }, err);
        });
        if (!isLast) {
          //TODO add ?synced=${props.behavior.synced}
          api.get(`/samples/${samples[state.sampleIdx].sampleId}`, {
            json: (sample) => {
              console.log(sample);
              if (sample) {
                console.log('valid');
                setState(prev => {
                    return Object.assign({}, prev, { sample: sample, currentErrors: new Set() });
                })
              }
            }
          })
        }
        else {
            setState(prev => Object.assign({}, prev, { finished: true }));
        }
    }, [state.sampleIdx]);
    useEffect(() => {
        if (isLast && state.finished) {
            if (behavior.annotated) {
                /*fetch(`${url}/errors`, {
                    ...POST_JSON,
                    ...{ body: JSON.stringify({ indexes: state.errors, analysisId: analysis.analysisId }) }
                }).then(async () => {
                    finish();
                    setState(initState);
                }*/
            }
            else {
                finish();
                setState(initState);
            }
        }
    }, [state.finished])
    const setErrors = (err) => setState(prev => Object.assign({}, prev, { currentErrors: err }));
    const nextBtn = state.sampleIdx < samples.length - 1 ? 'Next' : 'Finish';
    const nextIcon = state.sampleIdx < samples.length - 1 ? faStepForward : faSignOutAlt;
    const advance = (inc) => () => setState(prev => Object.assign({}, prev, { sampleIdx: prev.sampleIdx + inc }));
    const containerStyle = state.sample ? {} : { display: 'none' };
    console.log("STATE", state, state && state.sample && state.sample.output && state.sample.output['AsbestosClassifier'])
    return (<div className='review-root-container' style={{ height: '100%', width: '100%', display: 'flex' }}>
      <div className='sample-view-container'>
        <img 
          src={`data:image/jpeg;base64, ${state && state.sample && state.sample.jpeg}`} 
          style={{ display: 'none' }}
        />
        <ClassifierPlugin.Viewer
          sample_={state && state.sample} 
          updateData={updatePluginData}
        />
        <SegmentationPlugin.Viewer 
          data={state.sample?.output['AsbestosSegmentation']} 
          api={api}
          workspaceId={state.sample && state.sample.metadata && state.sample.metadata.workspaceId}
          dimensions={state.sample && state.sample.dimensions}
          sample={state.sample}
          metadata={state.sample && state.sample.metadata && state.sample.metadata.plugins['AsbestosSegmentation']} />
      </div>
      <div className="toolbar-container">
        <div className="toolbar-view">
          <h4>{analysis.request.title}</h4>
          <div className="toolbar-view-container">
            <span className="toolbar-key">Date</span>
            <strong className="toolbar-value">12.03.2020</strong>
          </div>
          <div className="toolbar-view-container">
            <span className="toolbar-key">Runtime <FontAwesomeIcon icon={faStopwatch}/></span>
            <strong className="toolbar-value">{analysis?.profile && 'elapsed_time' in analysis?.profile ? `${analysis?.profile['elapsed_time'].toPrecision(3)}s` : ''}</strong>
          </div>
          <div className="toolbar-view-container">
            <span className="toolbar-key">N°</span>
            <strong className="toolbar-value">
              <CircularProgress variant="determinate" size={20} value={(state.sampleIdx / samples.length) * 100}/>
              {state.sampleIdx + 1}
            </strong>
          </div>
          <div className="toolbar-view-container">
            <span className="toolbar-key">Number of samples</span>
            <strong className="toolbar-value">{analysis.metadata.length}</strong>
          </div>
          <div className="toolbar-view-container" style={state.sample ? {} : { display: 'none' }}>
            <span className="toolbar-key">X</span>
            <span className="toolbar-value">{state.sample ? (state.sample.metadata.position[0] * 1000).toFixed(2) : ''}</span>
          </div>
          <div className="toolbar-view-container" style={state.sample ? {} : { display: 'none' }}>
            <span className="toolbar-key">Y</span>
            <span className="toolbar-value">{state.sample ? (state.sample.metadata.position[1] * 1000).toFixed(2) : ''}</span>
          </div>
          <div className="toolbar-view-container" style={state.sample ? {} : { display: 'none' }}>
            <span className="toolbar-key">Working Distance</span>
            <span className="toolbar-value">{state.sample?.wd ? (state.sample.wd * 1000).toFixed(2) : ''}</span>
          </div>
          <div className="toolbar-view-container" style={containerStyle}>
            <span className="toolbar-key">Show classification</span>
            <Checkbox checked={params.showClassification} onChange={(event) => setParams({ showClassification: !params.showClassification })}/>
          </div>
          <div className="toolbar-view-container" style={state.sample ? {} : { display: 'none' }}>
            <SegmentationPlugin.Toolbar data={state.sample} metadata={getPluginMetadata(state.sample)}/>
          </div>
        </div>
        <div className="toolbar-tools">
          <Button size="sm" variant="light" onClick={advance(-1)} style={state.sampleIdx > 0 ? {} : { display: 'none' }}><FontAwesomeIcon icon={faStepBackward} style={{ marginLeft: '8px', marginRight: '4px' }}/> Back</Button>
          <Button className="sample-review-next-btn" id="sample-review-next-btn" size="sm" variant="light" onClick={advance(1)}>{nextBtn} <FontAwesomeIcon icon={nextIcon} style={{ marginLeft: '4px' }}/></Button>
        </div>
      </div>
    </div>)
}
function Retrospective(props) {
    const [upstreamAnalysis, setUpstreamAnalysis] = useState();
    const [state, setState] = useState({ saved: true, notes: props.analysis.notes });
    const [v, setV] = useState(0); //trick to trigger effect
    useEffect(() => {
        fetch(`${props.url}/analysis/${props.analysis.analysisId}`).then((res) => res.json()).then(setUpstreamAnalysis);
    }, [props.url, props.analysis.analysisId, props.analysis.status, v]);
    const saveNotes = () => {
        /*fetch(`${props.url}/analysis/${props.analysis.analysisId}/notes`, Object.assign({}, POST_JSON, { body: JSON.stringify({ text: state.notes }) })).then((res) => {
            setV(p => p + 1);
            setState(Object.assign({}, state, { saved: true }));
        })*/
    };
    const changeNotes = (e) => {
        setState({ saved: false, notes: e.target.value });
    };
    const numberOfErrors = upstreamAnalysis?.errors && Object.values(upstreamAnalysis.errors).length > 0 ? Object.values(upstreamAnalysis.errors).map((idx) => idx.length).reduce((a, b) => a + b) : 0;
    let time = '';
    let dateStr = '';
    if (props.analysis) {
        const date = new Date(props.analysis.date);
        time = `${date.getHours().toString().padStart(2, '0')}h${date.getMinutes().toString().padStart(2, '0')}`;
        dateStr = `${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`;
    }
    return (<div>

        <h1>{props.analysis ? props.analysis.request.title : 'Loading...'}</h1>
        <small>
          <FontAwesomeIcon icon={faCalendarAlt}/> {dateStr} {time}
          {props.analysis?.profile && 'elapsed_time' in props.analysis?.profile ? <span><FontAwesomeIcon icon={faStopwatch}/> {` ${props.analysis?.profile['elapsed_time'].toPrecision(3)}s`}</span> : ''}
        </small>
      <div className="retrospect-main-container">
        {numberOfErrors} errors
        <div style={{ width: '500px' }}>
        <ThemeProvider theme={darkTheme}>
          <TextField id="outlined-multiline-static" label="Notes" multiline value={state.notes} onChange={changeNotes} rows={5} fullWidth placeholder="optional..." variant="outlined"/>
        </ThemeProvider>
        </div>
        {!state.saved ? <Button onClick={saveNotes}>Save</Button> : ''}
      </div>
      <div className="bottom-right-container">
        <Button size="sm" variant="light" onClick={props.close}>
          Exit
        </Button>
      </div>
    </div>);
}
export default function Review({active, menu, analysis, api, close, setTab}) {
    const [all, setAll] = useState(true);
    const [params, setParams] = useState({ synced: false, annotated: false, fibercnt: false });
    const [state, setState] = useState('preview');
    useEffect(() => setState('preview'), [active, analysis?.status]);
    useEffect(() => {
        if (active) {
            menu.set(["Preview", "Review", "Retrospective"])
        }
    }, [active])
    if (!active || !analysis)
        return <span />
    const activeTab = menu?.active?.toLowerCase()
    return (
      <div>
        {activeTab === 'review' && (
          <SampleReview 
            api={api} 
            analysis={analysis} 
            behavior={params} 
            setValues={(vals) => { }} 
            finish={() => {
              menu.setTab('retrospective');
            }} 
            all={all}/>
        )}
        {activeTab === 'retrospective' && (
          <Retrospective 
            url={undefined} 
            close={() => close('Review')} 
            errors={[]} 
            analysis={analysis}/>
        )}
        {activeTab === 'preview' && (
          <Preview 
            url={undefined} 
            start={(params, mode) => {
              menu.setTab('Review')
            }} 
            analysis={analysis}/>
        )}
      </div>
    );
}
