import React, { useState, useEffect, useRef } from 'react';
import './Archive.css';
import { Checkbox, TextField, Select, MenuItem, Slider } from '@material-ui/core';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faStepBackward, faStepForward, faEye } from '@fortawesome/free-solid-svg-icons';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
const darkTheme = createTheme({
    palette: {
        type: "dark"
    }
});
function StatBanner(props) {
    return (<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '4px' }}>
      <h5>{props.data}</h5>
      <small>{props.tag}</small>
    </div>);
}
function humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
    return bytes.toFixed(dp) + ' ' + units[u];
}
function StatsBoard(props) {
    const [stats, setStats] = useState();
    useEffect(() => {
        fetch(`${props.url}/statistics`)
            .then((res) => res.json())
            .then((res) => {
            if (res.stats)
                setStats(res.stats[0]);
        })
            .catch(err => { });
    }, []);
    const style = { marginRight: '16px' };
    //{}borderTop: '1px gray solid', borderBottom: '1px gray solid', 
    return (<div style={{ backgroundImage: 'radial-gradient(circle, #606060 5%, #313131, #303030)', padding: '8px' }}>
      <h3>Stats</h3>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <div style={style}>
          <h5>Storage</h5>
          <div style={{ display: 'flex' }}>
            <StatBanner data={humanFileSize(stats?.storage.total || 0)} tag={'total'}/>
            <StatBanner data={humanFileSize(stats?.storage.samples || 0)} tag={'samples'}/>
          </div>
        </div>
        <div style={style}>
          <h5>Samples</h5>
          <div style={{ display: 'flex' }}>
            <StatBanner data={(stats?.samples.patches.positives || 0).toString()} tag={'positives'}/>
            <StatBanner data={(stats?.samples.patches.negatives || 0).toString()} tag={'negatives'}/>
            <StatBanner data={(stats?.samples.patches.falses || 0).toString()} tag={'falses'}/>
          </div>
        </div>
        <div style={style}>
          <h5>Captures</h5>
          <div style={{ display: 'flex' }}>
            <StatBanner data={(stats?.captures.running || 0).toString()} tag={'running'}/>
            <StatBanner data={(stats?.captures.captured || 0).toString()} tag={'captured'}/>
            <StatBanner data={(stats?.captures.reviewed || 0).toString()} tag={'reviewed'}/>
            <StatBanner data={(stats?.captures.aborted || 0).toString()} tag={'stopped'}/>
          </div>
        </div>
      </div>
    </div>);
}
function ImageBox(props) {
    return (<div className="segmentation-imgbox-container">
      <span className="segmentation-img-label">{props.label}</span>
      <div className="segmentation-img-container">
        <img src={`data:image/jpeg;base64, ${props.img}`}/>
      </div>
    </div>);
}
function SegmentationAnnotation(props) {
    const [ds, setDs] = useState({ name: "", sources: "" });
    const [datasets, setDatasets] = useState([]); //useState<Array<SegmentationDataset>([])
    const [meta, setMeta] = useState();
    const defaultAnnotation = { masks: {}, threshold_min: 180, threshold_max: 255, area_min: 70, area_max: 1000000, connectivity: 8 };
    const [annotation, setAnnotation] = useState(defaultAnnotation);
    const initDs = () => {
        fetch(`${props.url}/datasets/segmentation`, {
            method: "POST",
            body: JSON.stringify({ name: ds.name, sources: ds.sources }),
            mode: "cors",
            headers: { 'Content-Type': 'application/json' },
        }).then((res) => res.json()).then((res) => {
            if (res) {
                setDs(res);
                if (res.files) {
                    setAnnotation(defaultAnnotation);
                    setMeta({ file: res.files[0] });
                }
            }
        });
    };
    useEffect(() => {
        fetch(`${props.url}/datasets`).then(res => res.json()).then((r) => {
            setDatasets(r.datasets);
        });
    }, []);
    useEffect(() => {
        if (meta?.file) {
            const file_ = meta.file.split("/");
            const file = file_[file_.length - 1].split(".")[0];
            fetch(`${props.url}/datasets/segmentation/${ds.datasetId}/${file}`, {
                method: "POST",
                body: JSON.stringify(annotation),
                mode: "cors",
                headers: { 'Content-Type': 'application/json' },
            }).then(res => res.json()).then((res) => {
                setMeta(res);
            });
        }
    }, [meta?.file, JSON.stringify(annotation)]);
    const addDataSource = () => {
    };
    const genSegData = () => {
    };
    const postMasks = () => {
    };
    const saveFile = () => {
        fetch(`${props.url}/samples/segmentation`, {
            method: "POST",
            body: JSON.stringify({ datasetId: ds.datasetId, image: meta?.img, segmentation: meta?.segmentation, srcFile: meta?.file }),
            mode: "cors",
            headers: { 'Content-Type': 'application/json' },
        }).then(res => {
            if (res.ok && ds && ds.files && meta) {
                const files = ds.files.slice(1, ds.files.length);
                const annotatedFiles = [meta.file].concat(ds.annotatedFiles ? ds.annotatedFiles?.slice() : []);
                const cpy = Object.assign({}, ds, { files: files, annotatedFiles: annotatedFiles });
                const record = { datasetId: cpy.datasetId, files: cpy.files, annotatedFiles: annotatedFiles, discardedFiles: ds.discardedFiles ? ds.discardedFiles : [] };

                fetch(`${props.url}/datasets/segmentation`, {
                    method: "PUT",
                    body: JSON.stringify(record),
                    mode: "cors",
                    headers: { 'Content-Type': 'application/json' },
                }).then(res => {
                    setDs(cpy);
                    setMeta({ file: files[0] });
                });
            }
        });
    };
    const discardFile = () => {
        if (ds && ds.files && meta) {
            const files = ds.files.slice(1, ds.files.length);
            const discardedFiles = [meta.file].concat(ds.discardedFiles ? ds.discardedFiles?.slice() : []);
            const cpy = Object.assign({}, ds, { files: files, discardedFiles: discardedFiles });
            const record = { datasetId: cpy.datasetId, files: cpy.files, annotatedFiles: ds.annotatedFiles ? ds.annotatedFiles : [], discardedFiles: discardedFiles };
            fetch(`${props.url}/datasets/segmentation`, {
                method: "PUT",
                body: JSON.stringify(record),
                mode: "cors",
                headers: { 'Content-Type': 'application/json' },
            }).then(res => {
                setDs(cpy);
                setMeta({ file: files[0] });
            });
        }
    };
    const show = ds?.datasetId ? { display: "none" } : {};
    const file = meta?.file ? meta?.file?.split('/')[meta?.file?.split('/').length - 1] : "";
    return (<div>
      <div className="segmentation-img-grid-container-container">
        <div className="segmentation-img-grid-container"> 
          <ImageBox img={meta?.img} label="Original image"/>
          <ImageBox img={meta?.threshold} label="Thresholded image"/>
          <ImageBox img={meta?.imgObjects} label="Image landmarks"/>
          <ImageBox img={meta?.finalImg} label="Annotated image"/>
        </div>
      </div>
      <div className="toolbar-container segmentation-sidebar">
        <div>
          <div style={ds?.datasetId ? { display: "none" } : {}}>
            <TextField fullWidth size="small" label="Dataset name" variant="outlined" value={ds.name} onChange={(e) => setDs(pds => Object.assign({}, pds, { name: e.target.value }))}/>
            <TextField fullWidth size="small" label="Data source" variant="outlined" value={ds.sources} onChange={(e) => {
            setDs(pds => Object.assign({}, pds, { sources: e.target.value }));
        }}/>
            <Button onClick={initDs}>Create</Button>
            <div>OR</div>
            {datasets.map(ds_ => (<div className="toolbar-view-container">{ds_.name} <small>{ds_.datasetId?.slice(0, 5)}</small> <Button size="sm" onClick={() => {
                if (ds_.files) {
                    setMeta({ file: ds_.files[0] });
                    setDs(ds_);
                }
            }}>Select</Button></div>))}
          </div>
          <div className="toolbar-view-container" style={ds?.datasetId ? { display: "none" } : {}}>
          </div>
          <div className="toolbar-view-container" style={ds?.datasetId ? {} : { display: "none" }}>
            <strong className="toolbar-key">Dataset</strong>
            <span className="toolbar-value">{ds.name}</span>
          </div>
          <div className="toolbar-view-container" style={(ds?.datasetId && meta?.file) ? {} : { display: "none" }}>
            <strong className="toolbar-key">File</strong>
            <span className="toolbar-value">{file}</span>
          </div>
          <div style={(ds?.datasetId && meta?.file) ? {} : { display: "none" }}>
            <strong className="toolbar-key">Masks</strong>
            <div style={{ height: "400px", overflow: "scroll" }}>
              {meta?.masks && meta.masks.map((mask, maskIdx) => (<div>
                  <img src={`data:image/jpeg;base64, ${mask}`} height="160px"/>
                  <Select value={maskIdx in annotation.masks ? annotation.masks[maskIdx] : 0} onChange={(e) => {
                setAnnotation(p => Object.assign({}, p, {
                    masks: Object.assign({}, p.masks, { [maskIdx]: e.target.value })
                }));
            }}>
                    <MenuItem value={0}>Background</MenuItem>
                    <MenuItem value={1}>Amosit</MenuItem>
                    <MenuItem value={2}>Chrysotil</MenuItem>
                    <MenuItem value={3}>Dust</MenuItem>
                  </Select>
                </div>))}
            </div>
          </div>
          <strong className="toolbar-key">Thresholding</strong>
          <div className="toolbar-view-container" style={(ds?.datasetId && meta?.file) ? {} : { display: "none" }}>
            <strong className="toolbar-key">Threshold min</strong>
            <div style={{ width: '200px' }}>
              <Slider value={annotation.threshold_min} onChange={(e, v) => setAnnotation(p => Object.assign({}, p, { threshold_min: v }))} min={0} max={255} step={1} valueLabelDisplay="on" aria-labelledby="range-slider"/>
            </div>
          </div>
          <div className="toolbar-view-container" style={(ds?.datasetId && meta?.file) ? {} : { display: "none" }}>
            <strong className="toolbar-key">Threshold max</strong>
            <div style={{ width: '200px', marginRight: '16px' }}>
              <Slider value={annotation.threshold_max} onChange={(e, v) => setAnnotation(p => Object.assign({}, p, { threshold_max: v }))} min={0} max={255} step={1} valueLabelDisplay="on" aria-labelledby="range-slider"/>
            </div>
          </div>
          <div className="toolbar-view-container" style={(ds?.datasetId && meta?.file) ? {} : { display: "none" }}>
            <strong className="toolbar-key">Area min</strong>
            <div style={{ width: '200px', marginRight: '16px' }}>
              <Slider value={annotation.area_min} onChange={(e, v) => setAnnotation(p => Object.assign({}, p, { area_min: v }))} min={0} max={255} step={1} valueLabelDisplay="on" aria-labelledby="range-slider"/>
            </div>
          </div>
          <div className="toolbar-view-container" style={(ds?.datasetId && meta?.file) ? {} : { display: "none" }}>
            <strong className="toolbar-key">Area max</strong>
            <div style={{ width: '200px', marginRight: '16px' }}>
              <Slider value={annotation.area_max} onChange={(e, v) => setAnnotation(p => Object.assign({}, p, { area_max: v }))} min={0} max={255} step={1} valueLabelDisplay="on" aria-labelledby="range-slider"/>
            </div>
          </div>
          <div>
            <Button size="sm" variant="danger" onClick={discardFile}>Skip</Button>
            <Button size="sm" variant="primary" onClick={saveFile}>Save</Button>
          </div>
        </div>
      </div>
    </div>);
}
function DateView(props) {
    if (!props.show)
        return <span />;
    const date = new Date(props.analysis.date);
    return (<td>
      {`${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`}
    </td>);
}
function FileView({
  open,
  api,
  analysis,
  show
}) {
    const [downloading, setDownloading] = useState(false);
    const [data, setData] = useState('');
    const downloadLink = useRef(null);
    const query = `${api.url}/analyses/${analysis.analysisId}/plugins/AsbestosClassifier/export`;

    useEffect(() => {
      if (data !== '' && downloadLink.current) {
          downloadLink.current?.click();
          console.log(downloadLink.current.download);
          console.log('clicked');
      }
    }, [data]);
    //{downloading ? <Spinner animation="border" size="sm" /> : <FontAwesomeIcon icon={faDownload} onClick={download} />}
    //`data:application/zip;base64,${data}`
    if (!show)
        return <span />;
    return (
      <td className="table-row-filename">
        {analysis.request.title} 
        <div className="row-button-group">
          <FontAwesomeIcon 
            className="table-row-filename-open" 
            onClick={open} 
            icon={faEye}/> 
          <FontAwesomeIcon 
            icon={faDownload}
            onClick={() => {
              api.blob(`/analyses/${analysis.analysisId}/plugins/AsbestosClassifier/export`, (data) => {
                const zipUrl = window.URL.createObjectURL(data)
                downloadLink.current.href = zipUrl
                downloadLink.current.download = "test.zip"
                downloadLink.current.click() 
              })
            }}
          />
          <a href={query} target="_blank" ref={downloadLink} download="test.zip">
            
          </a>
        </div>
      </td>
    );
}
function StatusView(props) {
    if (!props.show)
        return <span />;
    switch (props.analysis.status) {
        case 'reviewed': return <td className="row-status-txt">Viewed</td>;
        case 'created': return <td className="row-status-txt">New</td>;
        default: return <span>?</span>;
    }
}
function TableFieldsProject(props) {
    const fields = ['status', 'date', 'filename', 'samples'];
    const [activeFields, setActiveFields] = useState(new Set(fields));
    useEffect(() => props.setFields(Array.from(activeFields)), []);
    const checkIncludeField = (field) => () => {
        const cpy = new Set(activeFields);
        if (activeFields.has(field)) {
            cpy.delete(field);
        }
        else {
            cpy.add(field);
        }
        setActiveFields(cpy);
        props.setFields(Array.from(cpy));
    };
    return (<div className="queries-row" key={"queries-row"}>
      <strong>Fields</strong>
      {fields.map((f) => <span><Checkbox checked={activeFields.has(f)} onChange={checkIncludeField(f)}/> {f}</span>)}
    </div>);
}
function ArchiveList({api, active, tabCtrl, setAnalysis}) {
    const [fields, setFields] = useState(['workspace', 'filename', 'status', 'date', 'samples'])
    const initState = { page: [], pageno: 0, totalpages: 0, docsPerPage: 10 }
    const [state, setState] = useState(initState)
    useEffect(() => {
      api.get(`/analyses?limit=${state.docsPerPage}&page=${state.pageno}`, {
        json: res => setState(prev => Object.assign({}, prev, res))
      })
    }, [active, state.pageno, state.docsPerPage])
    const incPageno = (inc) => () => setState(prev => {
        let val = prev.pageno + inc;
        if (val >= prev.totalpages || val < 0)
            val = prev.pageno;
        return Object.assign({}, prev, { pageno: val })
    });
    const others = fields.filter((f) => f !== 'status');
    const blanks = Array.from(Array(state.docsPerPage - state.page.length).keys());
    return (<div>
      <div className="archive-table-container">
        <table>
          <thead>
            <tr>{fields.map((f) => <th key={`${f}-head`}>{f}</th>)}</tr>
          </thead>
          <tbody>
            {state.page.map((record, i) => {
            const open = () => {
              setAnalysis(record)
              tabCtrl.open('Review', null)
            }
            return (
              <tr className="body-container" key={`${i}-data`}>
                <td>{record.request.workspaceName}</td>
                <FileView 
                  show={true} 
                  analysis={record} 
                  open={open} 
                  api={api}/>
                <DateView show={true} analysis={record}/>
                <StatusView show={true} analysis={record}/>
                <td>{record.metadata.length}</td>
              </tr>
            )
        })}
            {blanks.map((_, i) => <tr key={`${i}-data`}><td colSpan={fields.length}></td></tr>)}
          </tbody>
          <tfoot>
            <tr><td id="arxiv-table-foot" align='right' colSpan={fields.length}>
              <FontAwesomeIcon className="pagination-control" icon={faStepBackward} onClick={incPageno(-1)}/>
              <span id="archive-pagination-text">{state.pageno + 1} of {state.totalpages}</span>
              <FontAwesomeIcon className="pagination-control" icon={faStepForward} onClick={incPageno(1)}/>
            </td></tr>
          </tfoot>
        </table>
      </div>
    </div>);
}
export function Archive({menu, active, tabCtrl, online, setAnalysis, api}) {
  useEffect(() => {
    if (active) {
      menu.set(["Classifications"])
    }
  }, [active])
  if (!active)
    return <span />
  return (
    <div className="archive-root">
      <ArchiveList 
        api={api}
        tabCtrl={tabCtrl} 
        setAnalysis={setAnalysis}/>
    </div>
  )
}
