import React, { useState, useEffect } from 'react';
import { Button, Badge } from 'react-bootstrap';
import { WorkspaceSettings } from './settings/Connection';
import './Settings.css';
import './settings/settings.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faFolder, faFileImage, faFileCsv } from '@fortawesome/free-solid-svg-icons';
import MaterialTextfield from '../utils/MaterialTextField';
import { TextField, Radio, FormControlLabel, Checkbox } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { OptionalView } from '../utils/Overlays';
const darkTheme = createMuiTheme({
    palette: {
        type: "dark"
    }
});
const BADGE_LEVEL = {
    warn: 'warning',
    info: 'info',
    fatal: 'danger',
    debug: 'dark'
};
function ProcessFeed(props) {
    return (<div className="feed-container">
      <table>
        <tbody>
        {props.feed.map((entry) => (<tr className="feed-row-container" key={entry.text}>
            <td className="feed-badge-col"><Badge variant={BADGE_LEVEL[entry.level]}>{entry.level}</Badge></td> <td className="feed-text-col">{entry.text}</td>
          </tr>))}
        </tbody>
      </table>
    </div>);
}
function IpPortForm(props) {
    const editing = props.editing;
    return (<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
      <MaterialTextfield value={props.value.ip} label="IP address" update={(u) => {
            props.setValue({ ip: u, port: props.value.port });
        }}/>
      <MaterialTextfield value={props.value.port.toString()} label="Port number" update={(u) => {
            props.setValue({ ip: props.value.ip, port: Number.parseInt(u) });
        }}/>
    </div>);
}
const DEFAULT_CONNECTION = (M) => {
    const conn = {
        microscope: {
            ip: '127.0.0.1',
            port: 5000,
            manufacturer: M[0],
        },
        name: 'untitled',
        workspaceId: undefined,
        status: "unset",
        env: {}
    };
    return conn;
};
export function ServerVersion(props) {
    const [version, setVersion] = useState();
    useEffect(() => {
        //TODO fetch version here
    }, [props.online, props.address, props.active]);
    if (!props.active || !version)
        return <span />;
    return <small id="settings-api-version">{version}</small>;
}
/*
export function AddressPresets(props: {address?: {ip: string, port: number}, }) {
  const [editing, setEditing] = useState(false)
  const [key, setKey] = useState('')
  const save = () => {

  }
  return (
    <div>
      <div style={{display:'flex', justifyContent: 'space-between'}}>
        <div>
          <FormControlLabel value="end" control={<Radio color="secondary" checked={config?.connection.active === 'remote'} onChange={switchActiveServer('remote')} />} label="Remote" />
          <FormControlLabel value="end" control={<Radio color="secondary" checked={config?.connection.active === 'local'} onChange={switchActiveServer('local')} />} label="Local" />
          
        </div>
        {editing ? <Button size="sm" onClick={save} variant="warning"><FontAwesomeIcon icon={faSave} style={{marginRight: '8px'}} /> Save</Button> : <span />}
      </div>
      <IpPortForm editing={false} value={props.address ? props.address! : {ip: '--', port: -1}} setValue={updateServer} />
    </div>
  )
}
*/
function ObjectNavigator(props) {
    return (<div className="db-command-result">
      <span>{JSON.stringify(props.obj)}</span>
    </div>);
}
function DatabaseDevTools(props) {
    const [query, setQuery] = useState('');
    const [result, setResult] = useState();
    const [res, setRes] = useState();
    const exec = () => {
        if (query) {
            const queryEnc = encodeURIComponent(query);
            fetch(`${props.url}/command?query=${queryEnc}`).then((res) => res.text()).then((s) => {
                const parsed = JSON.parse(JSON.parse(s));
                setRes(parsed);
                setResult(JSON.stringify(parsed));
            });
        }
    };
    return (<ThemeProvider theme={darkTheme}>
      <div>
        <TextField multiline rows={5} fullWidth value={query} onChange={(e) => setQuery(e.target.value)} onKeyDown={(e) => {
            if (e.shiftKey && e.key == 'Enter') {
                e.preventDefault();
                exec();
            }
            if (e.key == 'Tab') {
                e.preventDefault();
                const target = e.target;
                let { selectionStart, selectionEnd } = target;
                if (query && selectionStart && selectionEnd) {
                    setQuery(query?.substring(0, selectionStart) + "\t" + query.substring(selectionEnd));
                    target.selectionStart = selectionStart + 1;
                }
            }
        }} label="Query" variant="outlined"/>
        <ObjectNavigator obj={res}/>
      </div>
    </ThemeProvider>);
}
function WorkspaceItem(props) {
    return (<div className="workspace-card">
      <div className="workspace-card-logo">
        <img src={`data:image/svg+xml;base64, ${props.ws.microscope.manufacturer.logo}`} alt="Logo" height='64px' className='workspace-card-logo'/>
      </div>
      <div className="workspace-card-content">
        <span className="workspace-card-title">
          {props.ws.name}
        </span>
        <span>
          {props.ws.microscope.ip}:{props.ws.microscope.port}
        </span>
        <button onClick={props.edit}>Edit</button>
      </div>
    </div>);
}
function WorkspacesSettings({online, api, show, refData}) {
  console.log(`[Workspace settings] ${api.url}`)
    const cardsWidth = '400px';
    const [workspaces, setWorkspaces] = useState([]);
    const [connectionsLoading, setConnectionsLoading] = useState(false);
    const {manufacturers} = refData
    const [editingId, setEditingId] = useState(-1);
    useEffect(() => {
      console.log(`[Workspace settings] entrypoint effect ${api.url}`)
      if (!api.isLive()) {
        console.warn("not online")
        setWorkspaces([])
        return
      }
      api.get("/workspaces", {
        json: ({workspaces}) => {
          console.log("Workspaces", workspaces)
          setWorkspaces(workspaces)
        },
        error: (err) => {
          setWorkspaces([])
          console.log("Error fetching workspaces", err)
        }
      })
    }, [api.isLive(), api.url])
    const updateConnection = (i) => (conn) => {
        if (!workspaces)
            return;
        const cpy = workspaces.slice();
        cpy[i] = conn;
        setWorkspaces(cpy.filter((c) => c));
    };
    const newConnection = () => {
        if (manufacturers.length > 0) {
            const cpy = workspaces?.slice()
            cpy?.push(DEFAULT_CONNECTION(manufacturers))
            setWorkspaces(cpy)
        }
        else {
            alert("No manufacturers found!")
        }
    };
    return (<div style={show ? {} : { display: 'none' }}>
      <div className="workspace-settings-container">
        <div className="workspace-settings-list-container">
          <button onClick={newConnection}>Create workspace</button>
          {workspaces.map((ws, i) => (<WorkspaceItem ws={ws} edit={() => setEditingId(i)}/>))}
        </div>
        <div className="workspace-settings-detail-container">
          {workspaces[editingId] && 
            <WorkspaceSettings 
              api={api}
              update={updateConnection(editingId)} 
              workspace={workspaces[editingId]} 
              active={true} 
              manufacturers={manufacturers}/>}
        </div>
      </div>
    </div>);
    /*return (
      <div style={props.show ? {} : {display: 'none'}}>
        {connectionsLoading ? (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <h3 style={{marginRight: '8px'}}>Workspaces</h3>
            <Spinner animation="border" size="sm" style={{display: 'none'}} />
          </div>
        ) : <h3>Workspaces</h3>}
        <div style={{display: 'flex', flexWrap: 'wrap', overflow: 'scroll', justifyContent: 'space-around'}}>
          
          {workspaces.map((conn: Workspace, i: number) => {
            return (
              
            )
          })}
        </div>
      </div>
    )*/
}
/*export function General({setConfig, show, api}) {
    const [config, setConfig] = useState()
    const [editingStorage, setEditingStorage] = useState(false)
    const [editingServer, setEditingServer] = useState(false)
    const activeServer = config ? config.connection.servers[config.connection.active] : undefined;
    const updateServer = (value) => {
        setEditingServer(true);
        if (!config)
            return;
        let cpy = Object.assign({}, config);
        cpy.connection.servers[cpy.connection.active].ip = value.ip;
        cpy.connection.servers[cpy.connection.active].port = value.port;
        console.log(cpy);
        setConfig(cpy);
    };
    const switchActiveServer = (kind) => () => {
        const cfg = Object.assign({}, config);
        cfg.connection.active = kind;
        setConfig(cfg);
        props.setConfig(cfg);
    };
    const commitServer = () => {
        console.log(config);
        if (!config)
            return;
        props.setConfig(config);
        setEditingServer(false);
    };
    const commitStorage = () => {
        if (!config)
            return;
        props.setConfig(config);
        setEditingStorage(false);
    };
    return (<div className="general-root" style={props.show ? {} : { display: 'none' }}>
      <small id="settings-client-version">Version {process.env.REACT_APP_VERSION}</small>
      <h3>Server</h3>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <FormControlLabel value="end" control={<Radio color="secondary" checked={config?.connection.active === 'remote'} onChange={switchActiveServer('remote')}/>} label="Remote"/>
            <FormControlLabel value="end" control={<Radio color="secondary" checked={config?.connection.active === 'local'} onChange={switchActiveServer('local')}/>} label="Local"/>
            
          </div>
          {editingServer ? <Button onClick={commitServer}>Save</Button> : <span />}
        </div>
        <IpPortForm editing={false} value={activeServer ? activeServer : { ip: '--', port: -1 }} setValue={updateServer}/>
        Version <ServerVersion address={props.url} online={props.online.server} active={true}/>
      
    </div>);
}*/
function HorizontalRadioMenu(props) {
    return (<div className="settings-radio-menu">
      <label>{props.label}</label>
      {props.options.map((opt) => (<div>
          <Radio onClick={() => props.onChange(opt)} checked={props.value === opt}/>
          <label>{opt}</label>
        </div>))}
    </div>);
}
function issueAnnotations(conf, files) {
    switch (conf.annotations) {
        case "None":
            return files;
        case "Individual":
            return files.map((file) => [file, file + ".gt"]).reduce((a, b) => a.concat(b));
        case "Global":
            return ["labels.csv"].concat(files);
    }
}
function renderFilename(filename, meta) {
    const fakeDate = new Date();
    let res = filename;
    if (meta.cls !== undefined) {
        res = res.replace("{class}", meta.cls.toString());
        //res = res.replace("{class.Name}", ["Clean", "Amosit", "Chrysotil", "Dust"][meta.cls])
        //res = res.replace("{class.name}", ["clean", "amosit", "chrysotil", "dust"][meta.cls])
    }
    /*if(meta.row !== undefined) {
      res = res.replace("{row}", meta.row.toString())
    }
    if(meta.col !== undefined) {
      res = res.replace("{col}", meta.col.toString())
    }*/
    if (meta.no !== undefined) {
        res = res.replace("{no}", meta.no.toString());
    }
    /*res = res.replace("{dd}", fakeDate.getDate().toString())
    res = res.replace("{mm}", fakeDate.getMonth().toString())
    res = res.replace("{yyyy}", fakeDate.getFullYear().toString())*/
    return res + (meta.cls !== undefined ? ".tif" : "");
}
function issueFiles(conf, classes) {
    switch (conf.files) {
        case "Images": return issueAnnotations(conf, classes.map((cls, i) => renderFilename(conf.filename, { cls: cls, no: i }))).concat(["..."]);
        case "Patches":
            const files = classes.flatMap(cls => [0, 1, 2].flatMap((row) => [0, 1, 2, 3].map((col) => [cls, row, col]))).slice(0, 3);
            return issueAnnotations(conf, files.map(([cls, row, col], i) => renderFilename(conf.filename, { cls: cls, row: row, col: col, no: i })).concat(["..."]));
    }
}
function renderObjectTree(obj) {
    if (obj instanceof Array) {
        const arr = obj;
        return (<div style={{ display: 'flex', flexDirection: 'column' }}>
        {arr.map((el) => renderObjectTree(el))}
      </div>);
    }
    if (typeof obj === "string") {
        const filename = obj;
        return <span>{obj !== "..." && <FontAwesomeIcon icon={obj.endsWith(".tif") ? faFileImage : faFileCsv}/>} {filename}</span>;
    }
    if (obj instanceof Object) {
        return (<div>
        {Object.entries(obj).map(([k, v]) => {
                return (<div>
              <div style={typeof k === "number" ? { display: "none" } : {}}><FontAwesomeIcon icon={faFolder}/> {k}</div>
              <div style={typeof k === "number" ? {} : { marginLeft: "16px" }}>{renderObjectTree(v)}</div>
            </div>);
            })}
      </div>);
    }
    return <span>EOF</span>;
}
function ArchiveSettings(props) {
    const [archiveConf, setArchiveConf] = useState({ files: "Images", subdir: "Flat", annotations: "None", classes: [0, 1, 2, 3], filename: "sample", archivename: "analysis" });
    const [saved, setSaved] = useState(true);
    const [conf, setConf] = useState();
    const toggleClass = (i) => () => {
        const set = new Set(archiveConf.classes.slice());
        if (set.has(i))
            set.delete(i);
        else
            set.add(i);
        setArchiveConf(Object.assign({}, archiveConf, { classes: Array.from(set) }));
        setSaved(false);
    };
    const menuChange = (menu) => (k) => {
        setArchiveConf(Object.assign({}, archiveConf, { [menu]: k }));
        setSaved(false);
    };
    const saveConf = () => {
        //TODO replace props.config.set()
        fetch(`${props.url}/users/default/archive/conf`, {
            method: "POST",
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(archiveConf)
        });
        setSaved(true);
    };
    const onFilenameChange = (e) => {
        setArchiveConf(Object.assign({}, archiveConf, { filename: e.target.value }));
        setSaved(false);
    };
    const onArchivenameChange = (e) => {
        setArchiveConf(Object.assign({}, archiveConf, { archivename: e.target.value }));
        setSaved(false);
    };
    let preview = {};
    const arxivname = renderFilename(archiveConf.archivename, {});
    if (archiveConf.subdir === "Flat") {
        preview[arxivname] = issueFiles(archiveConf, archiveConf.classes);
    }
    else if (archiveConf.subdir === "Class") {
        preview[arxivname] = Object.fromEntries(archiveConf.classes.map((i) => [i, issueFiles(archiveConf, [i])]));
    }
    else if (archiveConf.subdir === "Positive/Negative") {
        preview[arxivname] = {
            pos: issueFiles(archiveConf, archiveConf.classes.filter((cl) => [1, 2].includes(cl))),
            neg: issueFiles(archiveConf, archiveConf.classes.filter((cl) => [0, 3].includes(cl)))
        };
    }
    console.log(archiveConf.classes);
    return (<div>
      <h1>Archive</h1>
      <div className="settings-archive-container">
        <h2>Export parameters</h2>
        <HorizontalRadioMenu value={archiveConf.files} label="Files" options={["Images", "Patches"]} onChange={menuChange("files")}/>
        <TextField fullWidth label="Filename" variant="outlined" size="small" value={archiveConf.filename} onChange={onFilenameChange}/>
        <HorizontalRadioMenu value={archiveConf.subdir} label="Subdirectory" options={["Flat", "Class", "Positive/Negative"]} onChange={(k) => setArchiveConf(Object.assign({}, archiveConf, { subdir: k }))}/>
        <HorizontalRadioMenu value={archiveConf.annotations} label="Annotations" options={["None", "Individual", "Global"]} onChange={(k) => setArchiveConf(Object.assign({}, archiveConf, { annotations: k }))}/> 
        <TextField fullWidth label="Archive name" variant="outlined" size="small" value={archiveConf.archivename} onChange={onArchivenameChange}/>
        <div className="settings-radio-menu">
          <label>Classes</label>
          <div><Checkbox checked={0 in archiveConf.classes} onClick={toggleClass(0)}/><label>Clean</label></div>
          <div><Checkbox checked={1 in archiveConf.classes} onClick={toggleClass(1)}/><label>Amosit</label></div>
          <div><Checkbox checked={2 in archiveConf.classes} onClick={toggleClass(2)}/><label>Chrysotil</label></div>
          <div><Checkbox checked={3 in archiveConf.classes} onClick={toggleClass(3)}/><label>Dust</label></div>
        </div>
        <h3>Preview</h3>
        <div>
          {renderObjectTree(preview)}
        </div>
        {!saved && <Button onClick={saveConf}>Save <FontAwesomeIcon icon={faSave}/></Button>}
      </div>
    </div>);
}

function GeneralSettings({apiVersion, show}) {
  if(!show) return <span />
  return (
    <div className='general-settings-container'>
      <div className='general-settings-version-container'>
        <span>&copy; Copyright 2022 Amiscan sàrl</span>
        <div className='general-settings-version-item'>
          <span>App version</span>
          <small>{process.env.REACT_APP_VERSION}</small>
        </div>
        <div className='general-settings-version-item'>
          <span>API version</span>
          <small>{apiVersion}</small>
        </div>
      </div>
    </div>
  )
}

export default function Settings({
  active,
  menu,
  online,
  api,
  refData
}) {
    const def = "general"
    useEffect(() => {
      if (!active) return
        menu.set(['General', 'Workspaces'])
    }, [active])
    return (
      <OptionalView show={active}>
        <GeneralSettings
          apiVersion={'0.1.1'}
          show={menu?.active === 'General'}
        />
        <WorkspacesSettings 
          api={api} 
          online={online} 
          refData={refData}
          show={menu?.active === 'Workspaces'}/>
      </OptionalView>
    )
}
