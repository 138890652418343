import React, { useState, useEffect, useRef } from 'react';
import RequestForm from '../RequestForm';
import './Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages, faInfoCircle, faStopwatch, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ListGroup, Button, ProgressBar, Badge, Overlay } from 'react-bootstrap';
import TabList from '../utils/Table';
import IconButton from '../utils/IconButton';
import { BlurOverlay } from '../utils/Overlays';



function timeDeltaRepr(now, start) {
    const seconds = Math.floor((now - start) / 1000);
    if (seconds < 5)
        return 'now';
    if (seconds < 60)
        return `${seconds} seconds ago`;
    if (seconds < 3600)
        return `${Math.floor(seconds / 60)} minutes ago`;
    if (seconds < 259200)
        return `${Math.floor(seconds / 3600)} hours ago`;
    return `${start.getDate()}.${start.getMonth()}.${start.getFullYear()} ${start.getHours()}h${start.getMinutes()}`;
}
function OverviewConnection(props) {
    if (!props.conn)
        return <span />;
    return (<span>
      {props.conn.name}
    </span>);
}

function TableHeader({columns, emptyCells}) {
  return (
    <tr>
      {columns.map(({name}) => <th>{name}</th>)}
      {Array.from(Array(emptyCells).keys()).map(x => <th></th>)}
    </tr>
  )
}

function getObjectPath(obj, path){
  for (var i=0, path=path.split('.'), len=path.length; i<len; i++){
      obj = obj[path[i]];
  }
  return obj
}

export function ReportsView({api, conns, reviews, openTab, setAnalysis}) {
  const now = new Date()
  const columns = [
    {name: 'Workspace', path: x => x.request.workspaceName},
    {name: 'Title', path: x => x.request.title},
    {name: 'Date', path: x => timeDeltaRepr(now, new Date(x.date))},
    {name: 'Samples', path: x => x.metadata.length}
  ]
  const emptyCells = 2
  const remove = (analysisId) => {
      /*api.delete(`/analysis/${analysisId}`, { 
        method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${props.token}`
          }
      })*/
  }
  const connMap = conns ? Object.fromEntries(conns.map((conn) => [conn.workspaceId, conn])) : {}
  reviews.sort((a, b) => new Date(b.date) - new Date(a.date))
  const reports = reviews.slice(0, 10)
  return (
        <table className='home-table'>
          <TableHeader columns={columns.concat([{name: ''}, {name: ''}])} emptyCells={0} />
          <tbody className="scrollable-table-body">
            {reports.map(report => {
              const now = new Date()
              const start = new Date(report.date)
              return (
                <tr className='home-table-row'>
                  {columns.map(({path}) => (
                    <td>{path(report)}</td>
                  ))}
                  <td>
                    <IconButton 
                      icon="folder-open" 
                      onClick={() => {
                        openTab('Review', null)
                        setAnalysis(report)
                      }} />
                  </td>
                  <td>
                    <IconButton 
                      icon="trash-alt" 
                      onClick={() => remove(report.analysisId)}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={columns.length + emptyCells}>
                {reports.length || 0} reports
              </td>
            </tr>
          </tfoot>
        </table>
  )
}

function LiveView({requests, api}) {
  /**Table parameters */
  const columns = [
    {name: 'Workspace', path: 'workspaceName'},
    {name: 'Title', path: 'title'},
    {name: 'Date', path: 'date'}
  ]
  /*State and control*/
  const [aborting, setAborting] = useState({});
  const [latest, setLatest] = useState({});
  const [show, setShow] = useState({ error: false, preview: false });
  const previewIcon = useRef(null)
  const failureBadge = useRef(null)
  const abort = (requestId) => {
      aborting[requestId] = true
      setAborting(aborting)
      api.delete(`/requests/${requestId}`)
  }
  const restart = (captureId) => {
    api.get(`/captures/restart/${captureId}`)
    //TODO set status to loading
  }
  //<td><IconButton icon="images" /></td>
  /**
   * .concat([{
        workspaceName: 'test',
        title: 'test0000000',
        progress: 0,
        error: 'testing',
        status: 'failed'
      }])
   */
  const emptyCells = 3
  return (
    <table className='home-table'>
      <TableHeader columns={columns} emptyCells={emptyCells} />
      <tbody className="scrollable-table-body">
        {requests.map(request => {
          const waiting = request.status === 'waiting' || !request.numberOfSamples
          const label = waiting ? 'waiting' : `${request.progress + 1}/${request.numberOfSamples}`
          const now = waiting || !request.numberOfSamples ? 100 : 100 * (1 + request.progress) / request.numberOfSamples
          const failed = request.status === 'failed'
          return (
            <tr className='home-table-row'>
              {columns.map(({path}) => (
                <td>{getObjectPath(request, path)}</td>
              ))}
              <td>
                <div style={{ width: '256px' }}>
                  <ProgressBar striped={waiting} animated={waiting} label={label} now={now}/>
                </div>
              </td>
              
              <td style={failed ? {} : { display: 'none' }} ref={failureBadge} >
                <IconButton 
                  icon="info" 
                  onClick={() => setShow(s => Object.assign({}, s, { error: !s.error }))} 
                  />
                <BlurOverlay 
                  className='progress-viewer-error-container' 
                  poiRef={failureBadge} 
                  show={failed && show.error} 
                  placement="top"
                >
                  <h2>Error</h2>
                  <div className='progress-viewer-error-output'>
                    {request.error}
                  </div>
                </BlurOverlay>
              </td>
              <td style={failed ? {} : { display: 'none' }}>
                <IconButton icon="rotate-right" onClick={() => restart(request.requestId)} />
              </td>
              <td>
                <IconButton icon="eject" onClick={() => abort(request.requestId)} />
              </td>
            </tr>
          )
        })}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={columns.length + emptyCells}>
            {requests.length || 0} requests
          </td>
        </tr>
      </tfoot>
    </table>
  )
}

export default function Home({live, active, token, menu, tabCtrl, workspaces, api, events}) {
    //console.log(props.url)
    const [status, setStatus] = useState({progressing: [], reviews: []})
    const [ts, setTs] = useState('')
    const [cts, setCts] = useState('')
    /*useEffect(() => {
      fetch(`${props.url}/cookies?param=ok`, { credentials: 'include' }).then(res => res.json()).then(res => {
        console.log(res)
        console.log(document.cookie)
        fetch(`${props.url}/cookie`, { credentials: 'include' })
      })
    }, [props.online.server, props.active])*/
    useEffect(() => {
        if (active) {
            menu?.set(undefined);
        }
    }, [active]);
    useEffect(() => {
        if (live) {
        }
        else {
            setStatus(undefined)
        }
    }, [live, active]);
    useEffect(() => {
        if (status) {
            if (status.progressing)
                for (let rec of status?.progressing)
                    if (rec.title === ts)
                        setCts(ts);
            if (status.reviews)
                for (let rec of status?.reviews)
                    if (rec.request.title === ts)
                        setCts(ts);
        }
    }, [status && JSON.stringify(status)]);
    useEffect(() => {
        if (!active) {
            setTs('');
            setCts('');
        }
    }, [active]);
    if (!active) {
        return <span />
    }
    return (
    <div style={{ height: '100%' }} className='home-root'>
      <RequestForm 
        setTs={setTs} 
        ts={cts} 
        tabCtrl={tabCtrl} 
        api={api}
        live={live} 
        workspaces={workspaces} 
      />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'stretch', alignItems: 'stretch', padding: '32px' }}>
        <div className='home-feed'>
          <h3>Live</h3>
          <LiveView 
            requests={events.requests} 
            api={api} />
        </div>
        <div className='home-feed'>
          <div className="home-reviews-title-bar">
            <h3>Recent reports</h3>
            <Badge 
              pill 
              variant="danger" 
              style={status?.reviews ? {} : { visibility: 'hidden' }}
            >
              {events?.reports?.length}
            </Badge>
          </div>
          <ReportsView 
            conns={workspaces} 
            api={api}
            openTab={tabCtrl.open} 
            setAnalysis={tabCtrl.setAnalysis} 
            token={token}
            reviews={events.reports} />
        </div>
      </div>
    </div>);
}
