import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import StageForm from './stage/StageForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faChevronLeft, faInfoCircle, faChevronRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import './RequestForm.css';
import { Tabs } from './utils/Tabs';
import KVInput from './utils/Inputs';
import { useAuth0 } from '@auth0/auth0-react';
const darkTheme = createTheme({
    palette: {
        type: "dark"
    }
})
function isValidPosition(s) {
    return !isNaN(+s);
}
function CustomCenterForm({setCenter, show, center}) {
    const changePos = (k) => (e) => {
        setCenter(Object.assign({}, center, {[k]: e.target.value}))
    }
    if(!show) return <span />
    return (<ThemeProvider theme={darkTheme}>
        <div className="custom-center-form-container">
          <div className='flexbox'>
            <label>X</label>
            <input type="text" value={center && center.x} onChange={changePos("x")} />
          </div>
          <div className='flexbox'>
            <label>Y</label>
            <input type="text" value={center && center.y} onChange={changePos("y")} />
          </div>
        </div>
      </ThemeProvider>
    )
}
function stageArrayToDict(stage) {
    return new Map(stage.map((plate) => [plate.idx, plate]));
}
function StageSelect({
  currentWorkspace, 
  plateCenters,
  setStage,
  plate, 
  setPlate, 
  available, 
  live, 
  workspaces
}) {
    const [stgIdx, setStgIdx] = useState(0);
    const numberOfStages = currentWorkspace ? Object.values(currentWorkspace.microscope.manufacturer.plates).length : 1;
    const inc = (sense) => () => setStage(sense)
    const stageSize = plateCenters ? plateCenters.length : 0
        //{ plate: prev.activeStage[key] }
    const nextColor = currentWorkspace ? { 'color': stgIdx < Object.values(currentWorkspace.microscope.manufacturer.plates).length ? 'white' : 'gray' } : {};
    const centerMap = plateCenters && Object.fromEntries(plateCenters.map(c => [c.idx, c]))
    return (
    <div className="stage-forms-container">
      <FontAwesomeIcon 
        id="stage-back" 
        className={`stage-ctrl`} 
        icon={faChevronLeft} 
        onClick={inc(-1)}/>
      <CustomCenterForm 
        show={(plateCenters === undefined || stgIdx > stageSize) && workspaces.length > 0}
        center={plate}
        setCenter={({x, y}) => setPlate({ idx: -1, x: x, y: y })}
      />
      {(plateCenters) && <StageForm 
        selected={plate && plate.idx} 
        size={144} 
        count={stageSize} 
        available={available} 
        onKeyClick={(idx) => setPlate(centerMap[idx])}
      />}
      <FontAwesomeIcon 
        id="stage-next" 
        className={"stage-ctrl"} 
        icon={faChevronRight} 
        onClick={inc(1)}/>
    </div>);
}

function MiniStatusIcon(props) {
  switch (props.status) {
      case 'ready':
          return <Spinner size="sm" animation="border"/>;
      case 'failing':
          return <FontAwesomeIcon icon={faInfoCircle} style={{ color: 'indianred' }}/>;
      default:
          return <span />;
  }
}
function MicroscopeView({workspaces, switchWorkspace, workspace: ws}) {
  const [workspaceIdx, setWorkspaceIdx] = useState(0);
  const N = workspaces.length
  useEffect(() => {
    switchWorkspace(workspaceIdx)
          //props.setLocation(prev => Object.assign({}, prev, { activeConnection: workspaces[connIdx] }));
  }, [workspaceIdx])
  if ((workspaces && workspaces.length == 0)) { //  || !location.activeConnection
      return <small id="micro-view-none">No microscope</small>;
  }
  const select = (inc) => () => {
      setWorkspaceIdx(idx => (idx + inc + N) % N)
  }
  if(!ws) return <span />
  return (
  <div className="connection-select-container">
    <FontAwesomeIcon 
      className="stage-ctrl" 
      id="stage-ctrl-prev" 
      icon={faChevronLeft} 
      onClick={select(-1)}/>
      <div className="connection-select-main">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {(ws && ws.microscope) && <img 
              style={{ marginRight: '8px' }} 
              alt="Logo" 
              width='20px' 
              src={`data:image/svg+xml;base64, ${ws.microscope.manufacturer.logo}`}
          /> }
          <strong id="micro-view-name">{ws && ws.name}</strong>
          <MiniStatusIcon status={ws.status}/>
          </div>
          <small id="micro-view-addr">
            {ws.microscope.ip.slice(0,16)}
            :
            {ws.microscope.port}
          </small>
      </div>
    <FontAwesomeIcon className="stage-ctrl" id="stage-ctrl-next" icon={faChevronRight} onClick={select(1)}/>
  </div>
  );
}

function Commands(props) {
    const [state, setState] = useState({ commands: Array.from(Array(20).keys()) });
    return (<div>
      <h5>Commands</h5>
      <div style={{ display: 'flex', flexWrap: 'wrap', width: '120px', alignContent: 'flex-end' }}>
        {props.requests.map((req) => (<OverlayTrigger key={`k${req.plate?.idx}`} overlay={<Tooltip id={`tooltip-${req.filename}`}>{req.filename}</Tooltip>}>
            <div className="command-bubble">{req.plate?.idx}</div>
          </OverlayTrigger>))}
        <div className="command-bubble" onClick={props.stashRequest}>
          <FontAwesomeIcon icon={faPlus}/>
        </div>
      </div>
    </div>);
}
function validateForm(request, stash, live) {
    let errors = {};
    let hasFailed = false;
    if (!live) {
        hasFailed = true;
        errors.external = 'Server offline'
    }
    if (request.filename === '') {
        hasFailed = true;
        errors.name = 'Name must be non-empty';
    }
    /*for(let req of stash) {
      if(req.filename === '') {
        hasFailed = true
        errors.name = 'Name must be non-empty'
      }
      if(!req.sampling.parameters) {
        errors.samplingParameter = "Must set sampling parameter"
      } else if(+Object.values(req.sampling.parameters!)[0] == NaN) {
        hasFailed = true
        errors.samplingParameter = `${Object.values(req.sampling.parameters!)[0]} is not a valid ${Object.keys(req.sampling.parameters!)[0]}`
      }
    }*/
    return [!hasFailed, errors];
}

const KV_INPUT_WIDTH='480px'

function PluginForm({values, plugin, setPlugin}) {
    //const requestParameters = plugin.parameters.filter((param) => !param.scopes || param.scopes?.includes('request'));
    const parameters = Object.fromEntries(plugin.parameters.map(x => [x.paramId, x]))
    if (plugin.parameters.length == 0) {
      return <span>No parameters for {plugin.pluginId}</span>
    }
    return (
      <div>
        {plugin.parameters.map(({paramId, value: defaultValue, description}, k) => (
          <KVInput 
            key={k}
            label={paramId}
            value={values[paramId] || defaultValue}
            info={description}
            update={(v) => setPlugin({
              pluginId: plugin.pluginId, 
              paramId: paramId, 
              value: v
            })}
            width={KV_INPUT_WIDTH}
          />
        ))}
      </div>)
}

function RequestParameters({request, setRequest, setPlugin, plugins}) {
  const tabs = plugins && ['General'].concat(plugins.map((plugin) => plugin.pluginId))
  //TODO find a way to replace the concat hack
  return (
    <div className="request-parameters">
      <Tabs tabs={tabs}>
        {[(<KVInput 
          label="Name"
          key={-1} 
          value={request.filename}
          update={(v) => setRequest({filename: v})}
          width={KV_INPUT_WIDTH}
        />)].concat(plugins.map((plugin, k) => 
          <PluginForm 
            key={k}
            label={plugin.pluginId}
            plugin={plugin} 
            values={request.pluginParameters && request.pluginParameters[plugin.pluginId] ? request.pluginParameters[plugin.pluginId] : {}}
            setPlugin={setPlugin}/>
          )).map(x => x)}
      </Tabs>
    </div>
  )
}

export function updateStage(state, direction) {
  const {workspace, stageId} = state
  const plates = Object.values(workspace.microscope.manufacturer.plates)
  const N = plates.length
  const newStageId = ((stageId + direction) + (N + 1) * 2) % (N+ 1)
  return {
    stageId: newStageId,
    stage: newStageId >= plates.length ? undefined : plates[newStageId]
  }
}

export function updatePlate(state, plate) {
  return {
    request: Object.assign({}, state.request, {plate: plate})
  }
}

export function updatePluginParameters(prev, paramUpdate) {
  const {pluginId: plugId, paramId: parId, value: v} = paramUpdate 
  const R = prev.request
  const P = R.pluginParameters || {}
  const pluginParams = plugId in P ? P[plugId] : {}
  const params = Object.assign({}, P, {
    [plugId]: Object.assign({}, pluginParams, {[parId]: v})
  })
  return {
    request: Object.assign({}, prev.request, {pluginParameters: params})
  }
}

function encodeRequests(requests) {

}

function resetState(prev, data) {
  return {
    isSubmitting: false,
    request: {filename: '', plate: undefined, pluginParameters: {}},
    plate: undefined
  }
}

export default function RequestForm({
  api, 
  show, 
  workspaces
}) {
  const { user } = useAuth0()
  //Component for launching analyses (set the microscope/workspace, sampling center, request paremeters)
  const EMPTY_STATE = {
    workspace: undefined,
    stageId: 0, // just the stage number in the list of stages.
    stage: undefined, // List of {center: , radius: }
    availablePlates: [], // plates that are not already in a request
    request: {filename: '', plate: undefined, pluginParameters: {}},
    formErrors: undefined, // errors to track form validation,
    isSubmitting: false, // indicates whether the request has been processed
    requestsQueue: []
  }
  const [state, setState] = useState(EMPTY_STATE) // {activeWorksa}
  useEffect(() => {
    //Init effect: when the workspaces change, we reset
    if(workspaces === undefined || workspaces.length == 0) return 
    setState(prev => {
      const stage = workspaces[0].microscope && Object.values(workspaces[0].microscope.manufacturer.plates)[0]
      const plates = stage && stage.centers.map(s => s.idx)
      return Object.assign({}, prev, {
        workspace: workspaces[0],
        stage: stage,
        stageId: 0,
        plate: undefined,
        availablePlates: plates || []
      })
    })
  }, [workspaces && JSON.stringify(workspaces)])
  const updateState = (transform) => val => {
    //transform must return an object with any of the keys to update
    setState(prev => Object.assign(
      {}, 
      prev, 
      transform(prev, val)
    ))
  }
  const submitRequest = () => {
    const requests = [state.request].map((r) => {
      return {
        title: r.filename,
        user: {
          userId: user.sub,
          username: user.nickname,
          email: user.email
        },
        workspaceId: state.workspace.workspaceId,
        workspaceName: state.workspace.name,
        requestType: "analysis",
        clientVersion: "0.0.0",//process.env.REACT_APP_VERSION,
        status: 'waiting',
        pluginParameters: r.pluginParameters ? r.pluginParameters : {},
        payload: {
          analysis: {
            plate: {idx: r.plate.idx, x: parseFloat(r.plate.x), y: parseFloat(r.plate.y)}
          }
        }
      }
    })
    console.log(requests)
    const data = JSON.stringify({
      requests: requests
    })
    api.post("/requests", data, {
      json: (res) => {
        //TODO reset state
        //We should wait for the request to show up in Home
        updateState(resetState)()

      },
      error: (err) => {
        //err.toString()
      }
    })
    updateState((prev) => ({isSubmitting: true}))()
  }
  return (
    <ThemeProvider theme={darkTheme}>
      <h3>Request Editor</h3>
      <div className='requests-form-container'>
        <div className='request-location-container'>
          <StageSelect 
            plateCenters={state.stage && state.stage.centers}
            plate={state.request.plate}
            available={state.availablePlates}
            setStage={updateState(updateStage)}
            setPlate={updateState(updatePlate)}
            workspaces={workspaces} />
          <MicroscopeView 
            switchWorkspace={updateState((state, workspaceId) => ({workspace: workspaces[workspaceId]}))}
            workspace={state.workspace}
            workspaces={workspaces}
          />
        </div>
        <RequestParameters 
          request={state.request} 
          setRequest={updateState((prev, request) => ({request: Object.assign({}, prev.request, request)}))}
          setPlugin={updateState(updatePluginParameters)}
          plugins={state.workspace ? state.workspace.plugins : []}
          errors={undefined} />
        <div className='request-end-container'>
          <Button 
            id="request-launch" 
            size='sm' 
            variant={false ? "danger" : (false ? "success" : "light")} 
            onClick={submitRequest}
          >
            <Spinner 
              animation="border" 
              size="sm" 
              style={Object.assign({ marginRight: '4px' }, state.isSubmitting ? {} : { display: 'none' })}/>
            Start{state.isSubmitting ? 'ing' : ''} 
            <FontAwesomeIcon 
              style={false ? { display: 'none' } : { marginLeft: '4px' }} 
              icon={faPlay}/> 
          </Button>
        </div>
      </div>
    </ThemeProvider>
  )
}
